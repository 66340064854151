import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Box, Typography, Grid, Paper, TextField, Button, Accordion, AccordionSummary, AccordionDetails, IconButton, FormControl, InputLabel, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Switch, CircularProgress, InputAdornment, Chip, LinearProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import SyncIcon from '@mui/icons-material/Sync';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { USAContext } from './USAContext';
import CountrySelector from '../components/CountrySelector';
import { debounce } from 'lodash';
import { alpha } from '@mui/material/styles';

const API_URL = process.env.REACT_APP_API_URL;
const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL || 'http://localhost:5000';

const needsImportInfo = (item, importInfo) => {
    const info = importInfo[item.item.asin];
    return !info?.countryOfOrigin || (info?.countryOfOrigin === 'CN' && !info?.htsCode);
};

const ReviewItemsBoxes = () => {
    const { 
        shipmentItems, 
        boxes, 
        asinData, 
        reloadAsinData, 
        plans, 
        selectedPlan, 
        setSelectedPlan, 
        refreshBoxes, 
        handleNext: contextHandleNext,
        selectedNickname
    } = useContext(USAContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [prices, setPrices] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [placementWarning, setPlacementWarning] = useState('');
    const [placementDetails, setPlacementDetails] = useState([]);
    const [importInfo, setImportInfo] = useState({});
    const [htsSuggestions, setHtsSuggestions] = useState({});
    const [saveNotification, setSaveNotification] = useState({});
    const [showOnlyMissing, setShowOnlyMissing] = useState(false);
    const [deletePhotoConfirm, setDeletePhotoConfirm] = useState({ show: false, asin: null, index: null });
    const [isHtsEnabled, setIsHtsEnabled] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [isLoadingHts, setIsLoadingHts] = useState({});
    const [globalError, setGlobalError] = useState('');
    const [pendingHtsCodes, setPendingHtsCodes] = useState({});

    const sortedPlans = [...plans].sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA; // Descending order: most recent first
      });

    useEffect(() => {
        if (shipmentItems.length > 0 && selectedPlan) {
            const initialPrices = shipmentItems.reduce((acc, item) => {
                acc[item.item.asin] = getPrice(item.item.asin).toFixed(2);
                return acc;
            }, {});
            setPrices(initialPrices);
        }
    }, [shipmentItems, asinData, selectedPlan]);

    const getPrice = (asin) => {
        const data = asinData.find(item => item.ASIN === asin);
        return data ? parseFloat(data.VALUE) : 0;
    };

    const calculateBoxValue = (box) => {
        return box.items.reduce((acc, item) => {
            return acc + (getPrice(item.ASIN) * item.QTY);
        }, 0);
    };

    const handlePriceChange = (asin, newPrice) => {
        setPrices(prevPrices => ({ ...prevPrices, [asin]: newPrice }));
    };

    const handlePriceBlur = async (asin, newPrice) => {
        if (!isNaN(newPrice) && newPrice.trim() !== '') {
            try {
                await fetch(`${API_URL}/api/update_price`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ asin, value: parseFloat(newPrice) })
                });

                reloadAsinData();
            } catch (error) {
                console.error('Error updating price:', error);
            }
        }
    };

    const handlePlanChange = (event) => {
        const plan = event.target.value;
        setSelectedPlan(plan);
        checkPlacementOptions(plan);
    }

    useEffect(() => {
        if (selectedPlan) {
            refreshBoxes();
        }
    }, [selectedPlan]);

    const filteredItems = selectedPlan ? shipmentItems
        .filter(item => 
            // First filter for items in the selected plan
        selectedPlan.items.some(planItem => planItem.msku === item.item.merchant_sku)
        )
        .filter(item => {
            // Then apply search filter
            const searchLower = searchTerm.toLowerCase();
            const matchesSearch = !searchTerm || 
                item.item.title.toLowerCase().includes(searchLower) ||
                item.item.asin.toLowerCase().includes(searchLower) ||
                item.item.merchant_sku.toLowerCase().includes(searchLower) ||
                item.item.fnsku.toLowerCase().includes(searchLower);

            // Then apply missing info filter if enabled
            if (showOnlyMissing) {
                const info = importInfo[item.item.asin];
                const missingCountryOfOrigin = !info?.countryOfOrigin;
                const missingHtsCode = info?.countryOfOrigin === 'CN' && !info?.htsCode;
                return matchesSearch && (missingCountryOfOrigin || missingHtsCode);
            }

            return matchesSearch;
        }) : [];

    const hasOver800 = boxes.some(box => calculateBoxValue(box) > 800);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setIsLoading(true);  // Start loading state
    
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64Data = reader.result.split(',')[1];
                const payload = {
                    name: file.name,
                    type: file.type,
                    base64Data: base64Data,
                    sizeBytes: file.size,
                };
    
                try {
                    const response = await fetch(`${API_URL}/proxy-retool`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    });
    
                    if (response.ok) {
                        const result = await response.json();
                        const base64PDF = result.response;
    
                        // Convert base64 PDF back to Blob and download it
                        const byteCharacters = atob(base64PDF);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const blob = new Blob([byteArray], { type: 'application/pdf' });
                        const downloadUrl = URL.createObjectURL(blob);
    
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.download = 'PN-LABELS.pdf';
                        link.click();
                    } else {
                        console.error('Error processing PDF:', response.statusText);
                    }
                } catch (error) {
                    console.error('Error uploading file:', error);
                } finally {
                    setIsLoading(false);  // End loading state
                }
            };
            reader.readAsDataURL(file);
        }
    };


    const checkPlacementOptions = (plan) => {
        if (!plan || !plan.placement_options || plan.placement_options.length === 0) {
            setPlacementWarning('No placement options available. Please submit your box contents.');
            setPlacementDetails([]);
            return;
        }
        console.log(plan.placement_options)
        const confirmedOptions = plan.placement_options.filter(option => option.confirmed_at);
        if (confirmedOptions.length === 0) {
            setPlacementWarning('No placement option has been confirmed. Please confirm a placement option.');
            setPlacementDetails([]);
            return;
        }

        const latestConfirmedOption = confirmedOptions.reduce((latest, current) => {
            return new Date(current.confirmed_at) > new Date(latest.confirmed_at) ? current : latest;
        });

        const shipmentDetails = latestConfirmedOption.shipments.map(shipment => {
            const skuCount = new Set(shipment.box_groups.flatMap(boxGroup => boxGroup.items.map(item => item.plan_item_id))).size;
            const unitCount = shipment.box_groups.flatMap(boxGroup => boxGroup.items).reduce((acc, item) => acc + item.quantity, 0);
            return {
                fcId: shipment.destination.warehouse_id,
                skuCount,
                unitCount
            };
        });

        setPlacementWarning('');
        setPlacementDetails(shipmentDetails);
    };

    const groupConsecutiveBoxes = (boxes) => {
        const groupedBoxes = [];
        let currentGroup = null;

        boxes.forEach((box, index) => {
            const boxKey = JSON.stringify({
                dimensions: `${box.length}x${box.width}x${box.height}`,
                items: box.items.map(item => ({ ASIN: item.ASIN, QTY: item.QTY }))
            });

            if (currentGroup && currentGroup.key === boxKey) {
                currentGroup.endIndex = index;
            } else {
                if (currentGroup) {
                    groupedBoxes.push(currentGroup);
                }
                currentGroup = {
                    key: boxKey,
                    startIndex: index,
                    endIndex: index,
                    box
                };
            }
        });

        if (currentGroup) {
            groupedBoxes.push(currentGroup);
        }

        return groupedBoxes;
    };

    const groupedBoxes = groupConsecutiveBoxes(boxes);

    const fetchImportInfo = async (asin) => {
        try {
            const response = await fetch(`${SERVER_API_URL}/api/usa-import-info/${asin}`);
            if (!response.ok) {
                throw new Error('Failed to fetch import info');
            }
            
            const data = await response.json();
            if (data) {
                // The photos array is already in the correct format from the server
                setImportInfo(prev => ({
                    ...prev,
                    [asin]: {
                        countryOfOrigin: data.country_of_origin || '',
                        htsCode: data.hts_code || '',
                        photos: data.origin_photos || []
                    }
                }));
            }
        } catch (error) {
            console.error('Error fetching import info:', error);
            setImportInfo(prev => ({
                ...prev,
                [asin]: {
                    countryOfOrigin: '',
                    htsCode: '',
                    photos: []
                }
            }));
        }
    };

    const validateImportInfo = (asin) => {
        const info = importInfo[asin];
        if (info?.countryOfOrigin === 'CN' && !info?.htsCode) {
            setValidationErrors(prev => ({
                ...prev,
                [asin]: 'HTS Code is required for items from China'
            }));
            return false;
        }
        setValidationErrors(prev => ({
            ...prev,
            [asin]: null
        }));
        return true;
    };

    const handleCountryChange = async (asin, selectedOption) => {
        const newCountry = selectedOption?.value || '';
        
        // Update import info
        setImportInfo(prev => ({
            ...prev,
            [asin]: {
                ...prev[asin],
                countryOfOrigin: newCountry
            }
        }));

        // Clear HTS code if changing from China to another country
        if (newCountry !== 'CN' && importInfo[asin]?.htsCode) {
            setImportInfo(prev => ({
                ...prev,
                [asin]: {
                    ...prev[asin],
                    htsCode: ''
                }
            }));
        }

        try {
            await fetch(`${SERVER_API_URL}/api/update-usa-import-info`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    asin: asin,
                    field: 'country_of_origin',
                    value: newCountry
                })
            });

            // Validate after country change
            validateImportInfo(asin);

            setSaveNotification(prev => ({ ...prev, [asin]: true }));
            setTimeout(() => {
                setSaveNotification(prev => ({ ...prev, [asin]: false }));
            }, 2000);

        } catch (error) {
            console.error('Error updating country of origin:', error);
            setImportInfo(prev => ({
                ...prev,
                [asin]: {
                    ...prev[asin],
                    countryOfOrigin: prev[asin]?.countryOfOrigin || ''
                }
            }));
        }
    };

    // Add a debounced refresh function to prevent too frequent updates
    const debouncedRefresh = useCallback(
        debounce((asin) => {
            fetchImportInfo(asin);
        }, 500),
        []
    );

    // Modify the useEffect for initial data loading
    useEffect(() => {
        if (selectedPlan && filteredItems.length > 0) {
            const loadImportInfo = async () => {
                const promises = filteredItems.map(item => fetchImportInfo(item.item.asin));
                await Promise.all(promises);
            };
            loadImportInfo();
        }
    }, [selectedPlan]);

    const handlePhotoDelete = async (asin, index) => {
        try {
            const photoToDelete = importInfo[asin]?.photos[index];
            if (!photoToDelete) {
                console.error('Photo not found');
                return;
            }

            // Check if we have a public_id (Cloudinary photo)
            if (photoToDelete.public_id) {
                // Delete from Cloudinary
                await fetch(`${SERVER_API_URL}/api/delete-origin-proof`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        public_id: photoToDelete.public_id 
                    })
                });
            }

            // Update local state
            const updatedPhotos = importInfo[asin].photos.filter((_, i) => i !== index);
            setImportInfo(prev => ({
                ...prev,
                [asin]: {
                    ...prev[asin],
                    photos: updatedPhotos
                }
            }));

            // Update database
            await fetch(`${SERVER_API_URL}/api/update-usa-import-info`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    asin: asin,
                    field: 'origin_photos',
                    value: updatedPhotos,
                    updatedBy: selectedNickname || 'system'
                })
            });

            // Close the confirmation dialog
            setDeletePhotoConfirm({ show: false, asin: null, index: null });

        } catch (error) {
            console.error('Error deleting photo:', error);
            // You might want to show an error message to the user here
        }
    };

    const defaultNickname = 'system';

    useEffect(() => {
        const newHtsEnabled = {};
        Object.keys(importInfo).forEach(asin => {
            newHtsEnabled[asin] = importInfo[asin]?.countryOfOrigin === 'CN';
        });
        setIsHtsEnabled(newHtsEnabled);
    }, [importInfo]);

    const getHtsSuggestions = async (asin) => {
        setIsLoadingHts(prev => ({ ...prev, [asin]: true }));
        setHtsSuggestions(prev => ({ ...prev, [asin]: null }));
        
        try {
            const item = shipmentItems.find(item => item.item.asin === asin);
            if (!item) throw new Error('Item not found');

            const response = await fetch(`${SERVER_API_URL}/api/suggest-hts-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    title: item.item.title,
                    asin: asin,
                    countryOfOrigin: importInfo[asin]?.countryOfOrigin
                })
            });
            
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.details || 'Failed to get HTS suggestions');
            }

            const data = await response.json();
            console.log('Received HTS suggestions:', data);

            // Store the suggested HTS code in pendingHtsCodes instead of applying it immediately
            if (data.matches && data.matches[0]?.HTS_id) {
                setPendingHtsCodes(prev => ({
                    ...prev,
                    [asin]: data.matches[0].HTS_id.toString()
                }));
            }

            setHtsSuggestions(prev => ({ ...prev, [asin]: data }));

        } catch (error) {
            console.error('Error getting HTS suggestions:', error);
            setHtsSuggestions(prev => ({ ...prev, [asin]: null }));
        } finally {
            setIsLoadingHts(prev => ({ ...prev, [asin]: false }));
        }
    };

    // Add function to apply pending HTS code
    const applyHtsCode = async (asin) => {
        const pendingCode = pendingHtsCodes[asin];
        if (!pendingCode) return;

        try {
            // Update local state
            setImportInfo(prev => ({
                ...prev,
                [asin]: {
                    ...prev[asin],
                    htsCode: pendingCode
                }
            }));

            // Update database
            await fetch(`${SERVER_API_URL}/api/update-usa-import-info`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    asin: asin,
                    field: 'hts_code',
                    value: pendingCode,
                    updatedBy: selectedNickname || 'system'
                })
            });

            // Show save notification
            setSaveNotification(prev => ({ ...prev, [asin]: true }));
            setTimeout(() => {
                setSaveNotification(prev => ({ ...prev, [asin]: false }));
            }, 2000);

            // Clear pending code
            setPendingHtsCodes(prev => {
                const newPending = { ...prev };
                delete newPending[asin];
                return newPending;
            });

            // Validate after update
            validateImportInfo(asin);

        } catch (error) {
            console.error('Error applying HTS code:', error);
        }
    };

    const validateAllItems = () => {
        let isValid = true;
        let errorMessages = [];

        filteredItems.forEach(item => {
            const asin = item.item.asin;
            const info = importInfo[asin];
            
            if (info?.countryOfOrigin === 'CN' && !info?.htsCode) {
                isValid = false;
                errorMessages.push(`${item.item.title} (${asin}) is from China but missing HTS code`);
            }
        });

        return { isValid, errorMessages };
    };

    const handleNextWithValidation = () => {
        const { isValid, errorMessages } = validateAllItems();
        
        if (!isValid) {
            setGlobalError(`Cannot proceed. Missing HTS codes for Chinese items:\n${errorMessages.join('\n')}`);
            return;
        }

        if (hasOver800) {
            setOpenDialog(true);
        } else {
            contextHandleNext();
        }
    };

    const handleHtsCodeChange = async (asin, newValue) => {
        try {
            // Update local state
            setImportInfo(prev => ({
                ...prev,
                [asin]: {
                    ...prev[asin],
                    htsCode: newValue
                }
            }));

            // Update database
            await fetch(`${SERVER_API_URL}/api/update-usa-import-info`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    asin: asin,
                    field: 'hts_code',
                    value: newValue,
                    updatedBy: selectedNickname || 'system'
                })
            });

            // Validate after update
            validateImportInfo(asin);

            // Show save notification
            setSaveNotification(prev => ({ ...prev, [asin]: true }));
            setTimeout(() => {
                setSaveNotification(prev => ({ ...prev, [asin]: false }));
            }, 2000);

        } catch (error) {
            console.error('Error updating HTS code:', error);
            // Revert local state on error
            setImportInfo(prev => ({
                ...prev,
                [asin]: {
                    ...prev[asin],
                    htsCode: prev[asin]?.htsCode || ''
                }
            }));
        }
    };

    // Add a function to get the specific warning message
    const getWarningMessage = (item) => {
        const info = importInfo[item.item.asin];
        if (!info?.countryOfOrigin) {
            return 'Missing country of origin';
        }
        if (info.countryOfOrigin === 'CN' && !info?.htsCode) {
            return 'Missing HTS code for Chinese item';
        }
        return '';
    };

    return (
        <Box sx={{ 
            p: { xs: 2, md: 3 }, // Responsive padding
            backgroundColor: '#f5f7fa',
            minHeight: '100vh',
            width: '100%',
            maxWidth: '100%'
        }}>
            {/* Header Section */}
            <Paper sx={{ 
                p: { xs: 2, md: 3 }, 
                mb: 3, 
                borderRadius: 2,
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            }}>
                <Grid container spacing={3} alignItems="center">
                    {/* Plan Selection */}
                    <Grid item xs={12} md={9}>
                        <FormControl fullWidth>
                            <InputLabel id="select-plan-label">Shipment Plan</InputLabel>
                            <Select
                                labelId="select-plan-label"
                                value={selectedPlan}
                                onChange={handlePlanChange}
                                displayEmpty
                                sx={{
                                    '& .MuiSelect-select': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1
                                    }
                                }}
                            >
                                <MenuItem value="" disabled>Select a Shipment Plan</MenuItem>
                                {sortedPlans.map((plan) => {
                                    const formattedDate = new Date(plan.created_at).toLocaleString('en-US', {
                                        month: 'short',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: '2-digit',
                                        hour12: true,
                                    });

                                    return (
                                        <MenuItem 
                                            key={plan.id} 
                                            value={plan}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 2
                                            }}
                                        >
                                            <Typography variant="body1" fontWeight={500}>
                                                {plan.inbound_plan_id}
                                            </Typography>
                                            <Box sx={{ display: 'flex', gap: 2, color: 'text.secondary' }}>
                                                <Typography variant="body2">
                                                    {formattedDate}
                                                </Typography>
                                                <Typography variant="body2">
                                                    {plan.packing_options[0].packing_groups.length} groups
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Convert Labels Button */}
                    <Grid item xs={12} md={3}>
                        <Button
                            fullWidth
                            variant="outlined"
                            component="label"
                            disabled={isLoading}
                            startIcon={isLoading ? <CircularProgress size={20} /> : null}
                            sx={{
                                height: '48px',
                                backgroundColor: 'white',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                        >
                            {isLoading ? 'Processing...' : 'Convert Labels'}
                            <input
                                type="file"
                                hidden
                                accept="application/pdf"
                                onChange={(e) => handleFileUpload(e, selectedPlan)}
                            />
                        </Button>
                    </Grid>
                </Grid>
            </Paper>

            {/* Main Content */}
            <Grid container spacing={3}>
                {/* Items Column */}
                <Grid item xs={12} lg={6}>
                    <Paper 
                        sx={{ 
                            p: { xs: 2, md: 3 }, 
                            borderRadius: 2,
                            height: { xs: 'auto', lg: 'calc(100vh - 250px)' },
                            minHeight: { xs: '500px', lg: 'auto' },
                            overflow: 'auto',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            transition: 'box-shadow 0.3s ease',
                            '&:hover': {
                                boxShadow: '0 4px 8px rgba(0,0,0,0.15)'
                            }
                        }}
                    >
                        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h5" fontWeight="bold">Items</Typography>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={showOnlyMissing}
                                        onChange={(e) => setShowOnlyMissing(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Show Items Missing:
                                        </Typography>
                                        <Chip 
                                            label="Country of Origin" 
                                            size="small" 
                                            sx={{ 
                                                height: '20px',
                                                '& .MuiChip-label': { 
                                                    fontSize: '0.75rem',
                                                    px: 1
                                                }
                                            }} 
                                        />
                                        <Typography variant="body2" color="text.secondary">
                                            or
                                        </Typography>
                                        <Chip 
                                            label="HTS Code (China)" 
                                            size="small"
                                            sx={{ 
                                                height: '20px',
                                                '& .MuiChip-label': { 
                                                    fontSize: '0.75rem',
                                                    px: 1
                                                }
                                            }} 
                                        />
                                    </Box>
                                }
                            />
                        </Box>

                        {/* Search Bar */}
                        <TextField
                            placeholder="Search by ASIN, Title, or SKU..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="action" />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ mb: 3 }}
                        />

                        {/* Items List */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {filteredItems.map((item, index) => (
                                <ItemCard 
                                    key={index}
                                    item={item}
                                    importInfo={importInfo}
                                    handleCountryChange={handleCountryChange}
                                    handleHtsCodeChange={handleHtsCodeChange}
                                    handleFileUpload={handleFileUpload}
                                    prices={prices}
                                    handlePriceChange={handlePriceChange}
                                    handlePriceBlur={handlePriceBlur}
                                    reloadAsinData={reloadAsinData}
                                    isHtsEnabled={isHtsEnabled}
                                    htsSuggestions={htsSuggestions}
                                    isLoadingHts={isLoadingHts}
                                    pendingHtsCodes={pendingHtsCodes}
                                    getHtsSuggestions={getHtsSuggestions}
                                    applyHtsCode={applyHtsCode}
                                    validationErrors={validationErrors}
                                    saveNotification={saveNotification}
                                    setDeletePhotoConfirm={setDeletePhotoConfirm}
                                    needsImportInfo={needsImportInfo}
                                />
                            ))}
                        </Box>
                    </Paper>
                </Grid>

                {/* Boxes Column */}
                <Grid item xs={12} lg={6}>
                    <Paper 
                        sx={{ 
                            p: { xs: 2, md: 3 }, 
                            borderRadius: 2,
                            height: { xs: 'auto', lg: 'calc(100vh - 250px)' },
                            minHeight: { xs: '500px', lg: 'auto' },
                            overflow: 'auto',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            transition: 'box-shadow 0.3s ease',
                            '&:hover': {
                                boxShadow: '0 4px 8px rgba(0,0,0,0.15)'
                            }
                        }}
                    >
                        <Typography variant="h5" fontWeight="bold" sx={{ mb: 3 }}>Boxes</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {groupedBoxes.map((group, index) => (
                                <BoxCard 
                                    key={index}
                                    group={group}
                                    calculateBoxValue={calculateBoxValue}
                                    getPrice={getPrice}
                                    shipmentItems={shipmentItems}
                                />
                            ))}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            {/* Create new components for ItemCard and BoxCard */}
        </Box>
    );
};

// New ItemCard component with complete implementation
const ItemCard = ({ 
    item, 
    importInfo,
    handleCountryChange,
    handleHtsCodeChange,
    handleFileUpload,
    prices,
    handlePriceChange,
    handlePriceBlur,
    reloadAsinData,
    isHtsEnabled,
    htsSuggestions,
    isLoadingHts,
    pendingHtsCodes,
    getHtsSuggestions,
    applyHtsCode,
    validationErrors,
    saveNotification,
    setDeletePhotoConfirm,
    needsImportInfo
}) => {
    const hasHtsCode = importInfo[item.item.asin]?.htsCode;
    const isFromChina = importInfo[item.item.asin]?.countryOfOrigin === 'CN';
    const needsHts = isFromChina && !hasHtsCode;
                                    
                                    return (
                                        <Accordion 
            sx={{ 
                '&:before': { display: 'none' },
                boxShadow: theme => needsImportInfo(item, importInfo) 
                    ? `0 0 0 2px ${theme.palette.error.main}` 
                    : '0 1px 3px rgba(0,0,0,0.1)',
                backgroundColor: 'white',
                mb: 2,
                borderRadius: '8px',
                overflow: 'hidden',
                transition: 'all 0.2s ease',
                '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                }
            }}
        >
            {/* Header Section */}
                                            <AccordionSummary 
                                                expandIcon={<ExpandMoreIcon />}
                sx={{
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    '& .MuiAccordionSummary-content': {
                        margin: '12px 0'
                    }
                }}
            >
                <Grid container spacing={2} alignItems="center">
                    {/* Image and Title */}
                    <Grid item xs={8}>
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                            {item.item.images[0] && (
                                <Box 
                                    sx={{ 
                                        width: 60, 
                                        height: 60, 
                                        flexShrink: 0,
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        borderRadius: 1,
                                        overflow: 'hidden'
                                    }}
                                >
                                    <img 
                                        src={item.item.images[0].large_url} 
                                        alt={item.item.title} 
                                                style={{
                                            width: '100%', 
                                            height: '100%', 
                                            objectFit: 'contain'
                                        }} 
                                    />
                                </Box>
                            )}
                            <Box>
                                <Typography 
                                    variant="subtitle1" 
                                    sx={{ 
                                        fontWeight: 500,
                                        fontSize: '0.95rem',
                                        mb: 0.5,
                                        color: 'text.primary'
                                    }}
                                >
                                    {item.item.title}
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                                                <Typography 
                                        variant="body2" 
                                        sx={{ 
                                            color: 'text.secondary',
                                            fontSize: '0.85rem'
                                        }}
                                    >
                                        ASIN: {item.item.asin}
                                    </Typography>
                                    {needsImportInfo(item, importInfo) && (
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: 'error.main',
                                                fontSize: '0.85rem',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 0.5
                                            }}
                                        >
                                            <ErrorOutlineIcon sx={{ fontSize: '1rem' }} />
                                            Missing required info
                                                                </Typography>
                                                            )}
                                </Box>
                            </Box>
                        </Box>
                                                    </Grid>

                    {/* Price */}
                    <Grid item xs={4}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <TextField
                                                            label="Price"
                                value={prices[item.item.asin]}
                                                            onChange={(e) => handlePriceChange(item.item.asin, e.target.value)}
                                                            onBlur={(e) => handlePriceBlur(item.item.asin, e.target.value)}
                                                            type="number"
                                size="small"
                                sx={{ 
                                    width: '150px',
                                    '& .MuiInputBase-input': {
                                        fontSize: '0.9rem',
                                        textAlign: 'right'
                                    }
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">$</InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton 
                                                size="small" 
                                                onClick={reloadAsinData}
                                                sx={{ p: 0.5 }}
                                            >
                                                <SyncIcon sx={{ fontSize: '1rem' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>

            {/* Details Section */}
            <AccordionDetails sx={{ p: 3 }}>
                <Grid container spacing={3}>
                    {/* Single Column Layout */}
                                                    <Grid item xs={12}>
                        {/* Country of Origin Section */}
                        <Paper 
                            elevation={0} 
                            sx={{ 
                                p: 2, 
                                border: '1px solid',
                                borderColor: 'divider',
                                borderRadius: 1,
                                mb: 3
                            }}
                        >
                            <Typography 
                                variant="subtitle2" 
                                sx={{ 
                                    mb: 2,
                                    color: 'text.primary',
                                    fontWeight: 600
                                }}
                            >
                                                                    Country of Origin
                                                                </Typography>
                                                                <CountrySelector
                                                                    value={importInfo[item.item.asin]?.countryOfOrigin || ''}
                                                                    onChange={(option) => handleCountryChange(item.item.asin, option)}
                                isClearable
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        minHeight: '40px',
                                        borderColor: '#e0e0e0'
                                    }),
                                    placeholder: (base) => ({
                                        ...base,
                                        fontSize: '0.9rem'
                                    })
                                }}
                            />
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="caption" color="text.secondary">
                                                                    Origin Proof Photos
                                                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
                                                                    {importInfo[item.item.asin]?.photos?.map((photo, photoIndex) => (
                                        <Box
                                                                            key={photoIndex} 
                                            sx={{ 
                                                                                position: 'relative',
                                                width: 48,
                                                height: 48
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={photo?.url || photo}
                                                                                alt={`Origin proof ${photoIndex + 1}`}
                                                                                style={{ 
                                                                                    width: '100%', 
                                                                                    height: '100%', 
                                                                                    objectFit: 'cover', 
                                                                                    borderRadius: '4px' 
                                                                                }}
                                            />
                                            <IconButton
                                                size="small"
                                                onClick={() => setDeletePhotoConfirm({ 
                                                                                            show: true, 
                                                                                            asin: item.item.asin, 
                                                                                            index: photoIndex 
                                                })}
                                                sx={{
                                                                                    position: 'absolute',
                                                    top: -8,
                                                    right: -8,
                                                    backgroundColor: 'error.main',
                                                                                    color: 'white',
                                                    '&:hover': {
                                                        backgroundColor: 'error.dark'
                                                    }
                                                }}
                                            >
                                                ×
                                            </IconButton>
                                        </Box>
                                    ))}
                                    <Button
                                        component="label"
                                        variant="outlined"
                                        sx={{ 
                                            width: 48, 
                                            height: 48, 
                                            minWidth: 'unset',
                                            p: 0
                                        }}
                                    >
                                        +
                                                                        <input
                                                                            type="file"
                                                                            hidden
                                                                            accept="image/*"
                                                                            onChange={(e) => handleFileUpload(e, item.item.asin)}
                                                                        />
                                    </Button>
                                </Box>
                            </Box>
                        </Paper>

                        {/* HTS Code Section */}
                        <Paper 
                            elevation={0} 
                            sx={{ 
                                p: 2, 
                                border: '1px solid',
                                borderColor: isFromChina ? (needsHts ? 'error.main' : 'success.main') : 'divider',
                                borderRadius: 1,
                                bgcolor: isFromChina ? (needsHts ? alpha('#ff5252', 0.05) : alpha('#4caf50', 0.05)) : 'transparent'
                            }}
                        >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Typography 
                                    variant="subtitle2" 
                                    sx={{ 
                                        color: 'text.primary',
                                        fontWeight: 600
                                    }}
                                >
                                    HTS Code
                                </Typography>
                                {isFromChina && (
                                    <Chip 
                                        label={needsHts ? "Required" : "Valid"}
                                        color={needsHts ? "error" : "success"}
                                        size="small"
                                    />
                                )}
                            </Box>
                            
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <TextField
                                    value={importInfo[item.item.asin]?.htsCode || ''}
                                    onChange={(e) => handleHtsCodeChange(item.item.asin, e.target.value)}
                                    disabled={!isFromChina}
                                    error={needsHts}
                                    placeholder={isFromChina ? "Enter HTS Code" : "Only required for items from China"}
                                    size="small"
                                    fullWidth
                                    sx={{ 
                                        '& .MuiInputBase-input': {
                                            fontSize: '0.9rem'
                                        },
                                        flexGrow: 1
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    size="small"
                                    disabled={!isFromChina}
                                    onClick={() => getHtsSuggestions(item.item.asin)}
                                    startIcon={<AutoFixHighIcon />}
                                    sx={{ 
                                        minWidth: '120px',
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    AI Suggest
                                </Button>
                            </Box>

                            {/* HTS Suggestions Display */}
                            {isLoadingHts[item.item.asin] ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    <CircularProgress size={24} />
                                </Box>
                            ) : htsSuggestions[item.item.asin] && (
                                <Box sx={{ mt: 2 }}>
                                    <Paper 
                                        variant="outlined" 
                                        sx={{ 
                                            p: 2,
                                            bgcolor: 'background.default'
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                                            <Box>
                                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                                    Suggested Classification:
                                                </Typography>
                                                <Typography variant="body1" fontWeight={500}>
                                                    {pendingHtsCodes[item.item.asin]}
                                                </Typography>
                                            </Box>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                onClick={() => applyHtsCode(item.item.asin)}
                                                disabled={!pendingHtsCodes[item.item.asin]}
                                                sx={{ ml: 2 }}
                                            >
                                                Apply
                                            </Button>
                                        </Box>
                                        {htsSuggestions[item.item.asin].confidence && (
                                            <Box sx={{ mt: 2 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Confidence:
                                                    </Typography>
                                                    <Typography variant="caption" fontWeight={500}>
                                                        {Math.round(htsSuggestions[item.item.asin].confidence * 100)}%
                                                    </Typography>
                                                </Box>
                                                <LinearProgress 
                                                    variant="determinate" 
                                                    value={htsSuggestions[item.item.asin].confidence * 100}
                                                    sx={{ 
                                                        height: 6,
                                                        borderRadius: 3,
                                                        bgcolor: alpha(theme => theme.palette.primary.main, 0.1)
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </Paper>
                                </Box>
                            )}
                        </Paper>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
};

// New BoxCard component with complete implementation
const BoxCard = ({ group, calculateBoxValue, getPrice, shipmentItems }) => {
                                const boxValue = calculateBoxValue(group.box);
    const boxRange = group.startIndex === group.endIndex 
        ? `Box ${group.startIndex + 1}` 
        : `Boxes ${group.startIndex + 1}-${group.endIndex + 1}`;
    
                                return (
        <Accordion 
            sx={{ 
                '&:before': { display: 'none' },
                boxShadow: theme => boxValue > 800 
                    ? `0 0 0 2px ${theme.palette.error.main}` 
                    : '0 1px 3px rgba(0,0,0,0.1)',
                backgroundColor: theme => boxValue > 800 
                    ? alpha(theme.palette.error.light, 0.1) 
                    : 'white',
                transition: 'all 0.2s ease',
                '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: theme => boxValue > 800
                        ? `0 4px 8px rgba(0,0,0,0.1), 0 0 0 2px ${theme.palette.error.main}`
                        : '0 4px 8px rgba(0,0,0,0.1)'
                },
                mb: 2,
                borderRadius: '8px',
                overflow: 'hidden'
            }}
        >
            <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    '& .MuiAccordionSummary-content': {
                        margin: '12px 0'
                    }
                }}
            >
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" fontWeight={500}>
                            {boxRange}
                                            </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="subtitle1" fontWeight={500} align="center">
                            {group.box.length}″ × {group.box.width}″ × {group.box.height}″
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography 
                            variant="subtitle1" 
                            fontWeight={500}
                            color={boxValue > 800 ? 'error.main' : 'inherit'}
                            align="right"
                        >
                            ${boxValue.toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                {group.box.items.map((item, idx) => {
                                                    const itemPrice = getPrice(item.ASIN);
                                                    const totalValue = itemPrice * item.QTY;
                                                    const shipmentItem = shipmentItems.find(si => si.item.merchant_sku === item.SKU);
                        const imageUrl = shipmentItem?.item.images?.[0]?.large_url;

                                                    return (
                                                        <Grid item xs={12} key={idx}>
                                <Paper 
                                    elevation={0} 
                                    sx={{ 
                                        p: 2, 
                                        border: '1px solid',
                                        borderColor: 'divider'
                                    }}
                                >
                                                                <Grid container spacing={2} alignItems="center">
                                                                    <Grid item xs={2}>
                                            {imageUrl && (
                                                <img 
                                                    src={imageUrl} 
                                                    alt={item.TITLE} 
                                                    style={{ 
                                                        width: '100%',
                                                        borderRadius: '4px'
                                                    }} 
                                                />
                                            )}
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                            <Typography variant="subtitle2">
                                                {item.TITLE}
                                            </Typography>
                                            <Grid container spacing={2} sx={{ mt: 1 }}>
                                                                            <Grid item xs={3}>
                                                    <Typography variant="caption" color="text.secondary">
                                                        ASIN
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {item.ASIN}
                                                    </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Quantity
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {item.QTY}
                                                    </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Price
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        ${itemPrice.toFixed(2)}
                                                    </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                    <Typography variant="caption" color="text.secondary">
                                                        Total
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        ${totalValue.toFixed(2)}
                                                    </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
    );
};

export default ReviewItemsBoxes;