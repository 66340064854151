import React, { useState, useEffect, useRef, useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './tailwind-datepicker.css'; // Ensure this file exists and is correctly configured
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Button from '@mui/material/Button';
import { FaDiscord } from 'react-icons/fa'; // Make sure react-icons is installed

import moment from 'moment';
import { generatePDF } from './labelGenerator';
import { BoxContext } from './BoxContext'; // Ensure BoxContext provides necessary data
import CountrySelector from './components/CountrySelector';
import USAImportInfo from './components/USAImportInfo';

const API_URL = process.env.REACT_APP_API_URL; // For other API calls
const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL || 'http://localhost:5000'; // Express server URL
const DISCORD_SERVER_URL = process.env.DISCORD_SERVER_URL || 'https://boxcontents.yyzprep.ca:3004';
function OutboundItem({ item, quantityInBoxes }) {
  const {
    asinData,
    shelvingData,
    updateShelvingQty,
    reloadShipmentData,
    removeShelving,
    servicesData,
    clientInfo,
    reloadAsinData,
    selectedNickname,
    shipmentItems, // Should contain all items in the shipment
    teamId, // Equivalent to clientId
  } = useContext(BoxContext); // Ensure BoxContext provides shipmentItems and teamId

  const [expiryDate, setExpiryDate] = useState(item.expiry_date ? new Date(item.expiry_date) : null);
  const [warning, setWarning] = useState(false);
  const [notification, setNotification] = useState(false);
  const dropdownRef = useRef(null);

  const [shelvingNotification, setShelvingNotification] = useState(false);
  const [priceNotification, setPriceNotification] = useState(false);
  const [weightNotification, setWeightNotification] = useState(false); // State for weight notification
  const [shelvingInputValues, setShelvingInputValues] = useState(
    shelvingData.reduce((acc, shelving) => {
      acc[shelving.KEY] = shelving.QTY;
      return acc;
    }, {})
  );
  const [removedQuantities, setRemovedQuantities] = useState({});
  const totalQuantity = item.quantity;
  const [selectedServices, setSelectedServices] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [printOption, setPrintOption] = useState('');
  const [showPrintOptionsCustom, setShowPrintOptionsCustom] = useState(false);
  const [showExpiryOptions, setShowExpiryOptions] = useState(false);
  const [isLoadingPrice, setIsLoadingPrice] = useState(false);
  const [separatorNumber, setSeparatorNumber] = useState(0); // State for separator number
  const [customQty, setCustomQty] = useState(totalQuantity);

  const [prepRequirements, setPrepRequirements] = useState([]);
  const [isLoadingPrep, setIsLoadingPrep] = useState(false);
  const [prepError, setPrepError] = useState(null);

  const currentYear = new Date().getFullYear();
  const maxYear = currentYear + 10;

  const presetOptions = [
    { label: '160 days', value: new Date(new Date().setDate(new Date().getDate() + 160)) },
    { label: '1 year', value: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) },
    { label: '2 years', value: new Date(new Date().setFullYear(new Date().getFullYear() + 2)) },
    { label: '3 years', value: new Date(new Date().setFullYear(new Date().getFullYear() + 3)) },
  ];

  const [importInfo, setImportInfo] = useState({
    countryOfOrigin: '',
    htsCode: '',
    photos: []
  });

  const [isLoadingImportInfo, setIsLoadingImportInfo] = useState(true);
  const [isLoadingHts, setIsLoadingHts] = useState(false);
  const [htsSuggestions, setHtsSuggestions] = useState(null);

  // Add new state for country save notification
  const [countrySaveNotification, setCountrySaveNotification] = useState(false);

  // Add this state for delete confirmation
  const [deletePhotoConfirm, setDeletePhotoConfirm] = useState({ show: false, index: null });

  // Add this state
  const [isHtsEnabled, setIsHtsEnabled] = useState(false);

  // Add these state variables near your other state declarations
  const [isPolling, setIsPolling] = useState(false);
  const [lastImageUpdate, setLastImageUpdate] = useState(null);
  const pollingTimeoutRef = useRef(null);
  const pollStartTimeRef = useRef(null);

  // Add this state to track the last known photo count
  const [lastKnownPhotoCount, setLastKnownPhotoCount] = useState(0);

  // Add this state to track if we're waiting for a response
  const [isProcessing, setIsProcessing] = useState(false);

  // Add this ref to track polling state
  const isPollingRef = useRef(false);

  useEffect(() => {
    setExpiryDate(item.expiry_date ? new Date(item.expiry_date) : null);
  }, [item]); // Runs when 'item' prop changes

  useEffect(() => {
    // Pre-select existing services
    const preSelectedServices = item.company_services.map(service => ({
      value: service.name,
      label: service.name,
      ITEM_ID: service.pivot.outbound_shipment_item_id, // Include the ITEM_ID
      service: service // Include the full service object
    }));
    setSelectedServices(preSelectedServices);
  }, [item]);

  const handlePriceCheck = async (asin) => {
    try {
      setIsLoadingPrice(true);
      // Fetch the price
      const response = await fetch(`${API_URL}/api/price?asin=${asin}`);
      const data = await response.json();
      
      if (data.price && data.price !== -1) { // Only update if we got a valid price
        // Update the local state
        setPrice(data.price);
        
        // Update the database
        const updateResponse = await fetch(`${API_URL}/api/update_price`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 
            asin: asin, 
            value: data.price,
            employee: selectedNickname
          })
        });

        if (!updateResponse.ok) {
          throw new Error('Failed to update price in database');
        }

        // Show success notification
        setPriceNotification(true);
        reloadAsinData();

        // Hide the notification after 0.5 seconds
        setTimeout(() => {
          setPriceNotification(false);
        }, 500);
      } else {
        console.warn('Invalid price received:', data);
        // Optionally show an error message to the user
        alert('Unable to fetch a valid price');
      }
    } catch (error) {
      console.error('Error fetching/updating price:', error);
      alert('Error updating price');
    } finally {
      setIsLoadingPrice(false);
    }
  };

  const handlePresetChange = (date) => {
    updateExpiryDate(date);
  };

  const formatCurrency = (value) => {
    return value;
  };

  const getAsinData = (asin) => {
    return asinData.find(data => data.ASIN === asin) || {};
  };

  const asinDataItem = getAsinData(item.item.asin);
  const [price, setPrice] = useState(asinDataItem.VALUE || ''); // Initialize price after asinDataItem is defined
  const [weight, setWeight] = useState(asinDataItem.WEIGHT || ''); // Initialize weight after asinDataItem is defined

  // Update price when asinDataItem changes
  useEffect(() => {
    setPrice(asinDataItem.VALUE || '');
  }, [asinDataItem.VALUE]);

  // Update weight when asinDataItem changes
  useEffect(() => {
    setWeight(asinDataItem.WEIGHT || '');
  }, [asinDataItem.WEIGHT]);

  const formatDate = (date) => {
    // Convert date to UTC to avoid timezone issues
    const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return utcDate.toISOString().split('T')[0]; // Return in 'YYYY-MM-DD' format
  };

  const customPrintDropdownRef = useRef(null); // Ref for custom print dropdown

  // Handle clicks outside the dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowExpiryOptions(false);
      }
      if (customPrintDropdownRef.current && !customPrintDropdownRef.current.contains(event.target)) {
        setShowPrintOptionsCustom(false); // Hide custom print options when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, customPrintDropdownRef]);

  const updateExpiryDate = async (date) => {
    const newExpiryDate = date ? formatDate(date) : null;
    const itemId = item.id;
    const shipmentId = item.shipment_id;

    const currentDate = new Date();
    const differenceInDays = (date - currentDate) / (1000 * 60 * 60 * 24);

    if (differenceInDays < 90) {
      setWarning(true);
    } else {
      setWarning(false);
    }

    try {
      console.log("ITEM ID WE'RE SENDING PATCH TO: ", itemId);
      const modifiedItem = {
        ...item,
        expiry_date: newExpiryDate
      };
      const response = await fetch(`${API_URL}/api/update-expiry`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId: itemId,
          shipmentId: shipmentId,
          item: modifiedItem,
        }),
      });

      if (response.ok) {
        console.log('Expiry date updated successfully');
        console.log(newExpiryDate);
        setExpiryDate(moment(newExpiryDate).toDate()); // Update state with new date
        setNotification(true); // Show notification
        reloadShipmentData();

        // Hide the notification after 0.5 seconds
        setTimeout(() => {
          setNotification(false);
        }, 500);
      } else {
        console.error('Failed to update expiry date');
        alert('Failed to update expiry date');
      }
    } catch (error) {
      console.error('Error updating expiry date:', error);
      alert('Error updating expiry date');
    } finally {
      setShowExpiryOptions(false);
    }
  };

  const handlePriceBlur = async (e) => {
    console.log('Price blur event:', e.target.value);
    const newValue = e.target.value;
    setPrice(newValue);
    console.log('New price:', newValue);

    try {
      const asinIdentifier = item.item.identifiers.find(id => id.identifier_type === 'ASIN').identifier;
      const response = await fetch(`${API_URL}/api/update_price`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ asin: asinIdentifier, value: newValue })
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error || 'Failed to update price');
      }

      console.log('Price updated successfully:', result.message);
      setPriceNotification(true); // Show notification
      reloadAsinData();

      // Hide the notification after 0.5 seconds
      setTimeout(() => {
        setPriceNotification(false);
      }, 500);
    } catch (error) {
      console.error('Error updating price:', error.message);
    }
  };

  const handleWeightBlur = async (e) => {
    console.log('Weight blur event:', e.target.value);
    const newValue = e.target.value;
    setWeight(newValue);
    console.log('New weight:', newValue);

    try {
      const asinIdentifier = item.item.identifiers.find(id => id.identifier_type === 'ASIN').identifier;
      const response = await fetch(`${API_URL}/api/update_weight`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ asin: asinIdentifier, weight: newValue })
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error || 'Failed to update weight');
      }

      console.log('Weight updated successfully:', result.message);
      setWeightNotification(true); // Show notification
      reloadAsinData();

      // Hide the notification after 0.5 seconds
      setTimeout(() => {
        setWeightNotification(false);
      }, 500);
    } catch (error) {
      console.error('Error updating weight:', error.message);
    }
  };

  const handleShelvingQtyChange = (key, value) => {
    setShelvingInputValues(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleShelvingQtyBlur = async (key, shelfCode) => {
    const newQty = shelvingInputValues[key];
    try {
      await updateShelvingQty(key, shelfCode, newQty, item.item.merchant_sku);
      setShelvingNotification(true); // Show notification

      // Hide the notification after 0.5 seconds
      setTimeout(() => {
        setShelvingNotification(false);
      }, 500);
    } catch (error) {
      console.error('Error updating shelving quantity:', error);
      alert('Error updating shelving quantity');
    }
  };

  let remainingQty = totalQuantity - quantityInBoxes; 

  // Function to handle the removal of specific quantities from the shelves
  const handleRemoveFromShelf = async (shelfKey, shelfCode, quantityToRemove) => {
    const currentQty = shelvingInputValues[shelfKey];
    const newQty = currentQty - quantityToRemove;
  
    if (newQty < 0) {
      alert('Cannot remove more than the available quantity on the shelf.');
      return;
    }
  
    setShelvingInputValues(prevState => ({
      ...prevState,
      [shelfKey]: newQty,
    }));
  
    setRemovedQuantities(prevState => ({
      ...prevState,
      [shelfKey]: (prevState[shelfKey] || 0) + quantityToRemove,
    }));
  
    try {
      await updateShelvingQty(shelfKey, shelfCode, newQty, item.item.merchant_sku);
      setShelvingNotification(true); // Show notification
  
      // Hide the notification after 0.5 seconds
      setTimeout(() => {
        setShelvingNotification(false);
      }, 500);
    } catch (error) {
      console.error('Error updating shelving quantity:', error);
      alert('Error updating shelving quantity');
    }
  };
  
  const updateServices = async (selectedServices) => {
    const servicesPayload = selectedServices.reduce((acc, service, index) => {
      acc[index] = {
        id: service.service.id,
        name: service.service.name,
        charge: service.service.charge,
        advanced_options: service.service.advanced_options,
        quantity: 1 // assuming quantity is always 1
      };
      return acc;
    }, {});

    try {
      const response = await fetch(`https://app.yyzprep.ca/api/shipments/outbound/${item.shipment_id}/outbound-shipment-item/${item.id}/services?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          services: servicesPayload
        }),
      });

      if (response.ok) {
        console.log('Services updated successfully');
        setNotification(true); // Show notification
        reloadShipmentData();

        // Hide the notification after 0.5 seconds
        setTimeout(() => {
          setNotification(false);
        }, 500);
      } else {
        console.error('Failed to update services');
        alert('Failed to update services');
      }
    } catch (error) {
      console.error('Error updating services:', error);
      alert('Error updating services');
    }
  };

  const handleServiceChange = (selectedOptions) => {
    setSelectedServices(selectedOptions || []);
    updateServices(selectedOptions || []);
    reloadShipmentData();
    console.log("Service added so we reloaded shipment data")
  };

  const checkOversize = (item) => {
    const lengthInches = item.length_mm / 25.4;
    const widthInches = item.width_mm / 25.4;
    const heightInches = item.height_mm / 25.4;
    const weightLbs = item.weight_gm / 453.592;

    const isOversized = lengthInches > 18 || widthInches > 14 || heightInches > 8 || weightLbs > 20;
    const isHugeOversized = isOversized && (lengthInches >= 25 || widthInches >= 25 || heightInches >= 25);

    if (isHugeOversized) return 'Huge Oversized';
    if (isOversized) return 'Oversized';
    return '';
  };

  const oversizeTag = checkOversize(item.item);

  let statusBadge = null;
  if (quantityInBoxes === 0) {
    statusBadge = (
      <span className="absolute top-0 right-0 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
        Not Started
      </span>
    );
  } else if (quantityInBoxes < totalQuantity) {
    statusBadge = (
      <span className="absolute top-0 right-0 bg-yellow-500 text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
        In Progress
      </span>
    );
  } else if (quantityInBoxes === totalQuantity) {
    statusBadge = (
      <span className="absolute top-0 right-0 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
        Completed
      </span>
    );
  } else if (quantityInBoxes > totalQuantity) {
    statusBadge = (
      <span className="absolute top-0 right-0 bg-red-700 text-white text-xs font-bold px-2 py-1 rounded-bl-lg">
        Over Limit
      </span>
    );
  }

  // Sort servicesData alphabetically by service name
  const sortedServices = [...servicesData.services].sort((a, b) => a.name.localeCompare(b.name));

  // Create options for react-select
  const serviceOptions = sortedServices.map(service => ({
    value: service.name,
    label: service.name,
    ITEM_ID: service.id, // Include the ITEM_ID
    service: service // Include the full service object
  }));

  const formatDimensions = (length, width, height) => {
    return `${Math.round(length / 10)} x ${Math.round(width / 10)} x ${Math.round(height / 10)} cm`;
  };

  const formatWeight = (weight) => {
    return `${(weight / 1000).toFixed(2)} kg`;
  };

  const handleMouseEnter = () => {
    const timeout = setTimeout(() => {
      setShowTooltip(true);
    }, 250); // 0.25 second delay
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout);
    setShowTooltip(false);
  };

  const handlePrintOptionChange = (quantity, option, setShowPrintOptions) => {
    setPrintOption(option);
    setShowPrintOptions(false);
    handlePrint(quantity, option);
  };
  
  const handlePrint = (quantity, option) => {
    generatePDF(item, quantity, expiryDate, option || printOption, separatorNumber);
    setPrintOption(''); // Reset the printOption after printing
  };
  
  const truncateText = (text, maxLength, firstPercentage = 60, lastPercentage = 40) => {
    if (text.length <= maxLength) return text; // Return the full text if it's shorter than maxLength
  
    const firstChars = Math.floor((firstPercentage / 100) * text.length); // First X% of characters
    const lastChars = Math.floor((lastPercentage / 100) * text.length); // Last Y% of characters
  
    const firstPart = text.slice(0, firstChars); // First X% of characters
    const lastPart = text.slice(-lastChars); // Last Y% of characters
  
    return `${firstPart}...${lastPart}`;
  };

  const togglePrintOptions = (setShowPrintOptions) => {
    setShowPrintOptions(prev => !prev);
  };

  useEffect(() => {
    const fetchPrepRequirements = async () => {
      // Extract all MSKUs from the shipment
      const mskus = shipmentItems.map(shipmentItem => shipmentItem.item.merchant_sku);
      
      if (mskus.length === 0) {
        setPrepError('No MSKUs found in the shipment.');
        return;
      }

      try {
        setIsLoadingPrep(true);
        setPrepError(null); // Reset any previous errors

        const queryParams = new URLSearchParams();
        queryParams.append('teamId', clientInfo.id);
        mskus.forEach(msku => queryParams.append('mskus[]', msku));

        const response = await fetch(`${SERVER_API_URL}/api/item-prep-details?${queryParams.toString()}`);

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to fetch prep requirements');
        }

        const data = await response.json();

        // Debugging: Log the fetched prep requirements
        console.log('Fetched prepRequirements:', data.prepRequirements);

        setPrepRequirements(data.prepRequirements);
      } catch (error) {
        console.error('Error fetching prep requirements:', error.message);
        setPrepError('Failed to fetch prep requirements.');
      } finally {
        setIsLoadingPrep(false);
      }
    };

    fetchPrepRequirements();
  }, [shipmentItems]);

  // Modify handleCountryChange
  const handleCountryChange = async (selectedOption) => {
    const newCountry = selectedOption ? selectedOption.value : '';
    setImportInfo(prev => ({ ...prev, countryOfOrigin: newCountry }));
    
    try {
      await fetch(`${SERVER_API_URL}/api/update-usa-import-info`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          asin: item.item.asin,
          field: 'country_of_origin',
          value: newCountry,
          updatedBy: selectedNickname
        })
      });

      // Show save notification
      setCountrySaveNotification(true);
      setTimeout(() => {
        setCountrySaveNotification(false);
      }, 2000);

    } catch (error) {
      console.error('Error updating country of origin:', error);
    }
  };

  const handleHtsChange = async (newHtsCode) => {
    setImportInfo(prev => ({ ...prev, htsCode: newHtsCode }));
    
    try {
      await fetch(`${SERVER_API_URL}/api/update-usa-import-info`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          asin: item.item.asin,
          field: 'hts_code',
          value: newHtsCode,
          updatedBy: selectedNickname
        })
      });
    } catch (error) {
      console.error('Error updating HTS code:', error);
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePhotoAdd = async (file) => {
    try {
      // Convert file to base64
      const base64Data = await convertToBase64(file);
      
      // Upload to server/Cloudinary
      const response = await fetch(`${SERVER_API_URL}/api/upload-origin-proof`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ base64Data })
      });

      if (!response.ok) {
        throw new Error('Failed to upload image');
      }

      const { url, public_id } = await response.json();
      
      // Update local state and database
      const updatedPhotos = [...importInfo.photos, { url, public_id }];
      setImportInfo(prev => ({ ...prev, photos: updatedPhotos }));
      
      // Update database
      await fetch(`${SERVER_API_URL}/api/update-usa-import-info`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          asin: item.item.asin,
          field: 'origin_photos',
          value: updatedPhotos,
          updatedBy: selectedNickname
        })
      });
    } catch (error) {
      console.error('Error uploading photo:', error);
    }
  };

  const handlePhotoDelete = async (index) => {
    try {
      const photoToDelete = importInfo.photos[index];
      if (!photoToDelete) {
        console.error('Photo not found');
        return;
      }

      // Check if we have a public_id (Cloudinary photo)
      if (photoToDelete.public_id) {
        // Delete from Cloudinary
        const response = await fetch(`${SERVER_API_URL}/api/delete-origin-proof`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            public_id: photoToDelete.public_id 
          })
        });

        if (!response.ok) {
          throw new Error('Failed to delete from Cloudinary');
        }
      }

      // Update local state
      const updatedPhotos = importInfo.photos.filter((_, i) => i !== index);
      setImportInfo(prev => ({
        ...prev,
        photos: updatedPhotos
      }));

      // Update database
      await fetch(`${SERVER_API_URL}/api/update-usa-import-info`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          asin: item.item.asin,
          field: 'origin_photos',
          value: updatedPhotos,
          updatedBy: selectedNickname
        })
      });

    } catch (error) {
      console.error('Error deleting photo:', error);
    } finally {
      // Close the confirmation dialog
      setDeletePhotoConfirm({ show: false, index: null });
    }
  };

  useEffect(() => {
    const fetchImportInfo = async () => {
      if (clientInfo.country === 'USA' && item.item.asin) {
        setIsLoadingImportInfo(true);
        try {
          const response = await fetch(`${SERVER_API_URL}/api/usa-import-info/${item.item.asin}`);
          if (!response.ok) {
            throw new Error('Failed to fetch import info');
          }
          
          const data = await response.json();
          if (data) {
            console.log('Loaded import info:', data);
            setImportInfo({
              countryOfOrigin: data.country_of_origin || '',
              htsCode: data.hts_code || '',
              photos: Array.isArray(data.origin_photos) ? data.origin_photos : [],
              last_updated: data.last_updated || null,
              created_at: data.created_at || null,
              updated_by: data.updated_by || null
            });
          }
        } catch (error) {
          console.error('Error fetching import info:', error);
        } finally {
          setIsLoadingImportInfo(false);
        }
      } else {
        setIsLoadingImportInfo(false);
      }
    };

    fetchImportInfo();
  }, [item.item.asin, clientInfo.country]); // Dependencies array

  const getHtsSuggestions = async () => {
    setIsLoadingHts(true);
    setHtsSuggestions(null);
    
    try {
      const response = await fetch(`${SERVER_API_URL}/api/suggest-hts-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          title: item.item.title,
          asin: item.item.asin,
          countryOfOrigin: importInfo.countryOfOrigin
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.details || 'Failed to get HTS suggestions');
      }

      const data = await response.json();
      console.log('Received HTS suggestions:', data);

      // Use the HTS_ID from the first match as the HTS code
      if (data.matches && data.matches.length > 0) {
        handleHtsChange(data.matches[0].HTS_id.toString());
      }

      setHtsSuggestions(data);

    } catch (error) {
      console.error('Error getting HTS suggestions:', error);
      setHtsSuggestions(null);
    } finally {
      setIsLoadingHts(false);
    }
  };

  // Update the useEffect for checking duties
  useEffect(() => {
    const checkDuties = async () => {
      if (importInfo.countryOfOrigin === 'CN' && importInfo.htsCode) {
        try {
          const response = await fetch(`${SERVER_API_URL}/api/check-section-301`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ htsCode: importInfo.htsCode })
          });
          
          if (!response.ok) throw new Error('Failed to check duties');
          
          const dutiesData = await response.json();
          console.log('Duties check result:', dutiesData);
          
          // Update HTS suggestions with duties info
          setHtsSuggestions(prev => prev ? {
            ...prev,
            duties: {
              section301: dutiesData.section301 || "No Section 301 duties found",
              feb2025Tariff: dutiesData.feb2025Tariff
            }
          } : null);
          
        } catch (error) {
          console.error('Error checking duties:', error);
        }
      }
    };

    checkDuties();
  }, [importInfo.countryOfOrigin, importInfo.htsCode]);

  const DutiesDisplay = ({ duties }) => {
    if (!duties) return null;

    return (
      <div className="mt-3 p-3 bg-gray-50 rounded-lg border border-gray-200">
        <h4 className="text-lg font-semibold text-red-600 mb-2">Duties Information:</h4>
        {duties.section301 ? (
          <div className="mb-2">
            <span className="font-medium">Section 301 Duty: </span>
            <span className="text-red-600">{duties.section301}</span>
          </div>
        ) : (
          <div className="mb-2">
            <span className="font-medium">Section 301 Duty: </span>
            <span className="text-gray-600">No Section 301 duties found</span>
          </div>
        )}
        {duties.feb2025Tariff && (
          <div>
            <span className="font-medium">Additional Tariff: </span>
            <span className="text-red-600">{duties.feb2025Tariff}</span>
          </div>
        )}
      </div>
    );
  };

  // Add this effect to watch for country changes
  useEffect(() => {
    setIsHtsEnabled(importInfo.countryOfOrigin === 'CN');
  }, [importInfo.countryOfOrigin]);

  const checkForImageUpdates = async () => {
    try {
      console.log('Checking for updates...', {
        asin: item.item.asin,
        lastUpdate: lastImageUpdate,
        lastKnownPhotoCount
      });

      const response = await fetch(
        `${SERVER_API_URL}/api/check-image-updates/${item.item.asin}?lastUpdate=${lastImageUpdate || ''}&lastKnownCount=${lastKnownPhotoCount}`
      );
      
      if (!response.ok) throw new Error('Failed to check for updates');
      
      const data = await response.json();
      console.log('Update check response:', data);
      
      if (data.hasUpdates || data.currentPhotoCount > lastKnownPhotoCount) {
        const importResponse = await fetch(`${SERVER_API_URL}/api/usa-import-info/${item.item.asin}`);
        if (!importResponse.ok) throw new Error('Failed to fetch updated import info');
        
        const importData = await importResponse.json();
        console.log('New import data:', importData);
        
        if (importData) {
          // Ensure the data structure is complete before updating state
          setImportInfo({
            countryOfOrigin: importData.country_of_origin || '',
            htsCode: importData.hts_code || '',
            photos: importData.origin_photos || [],
            last_updated: importData.last_updated || null,
            created_at: importData.created_at || null,
            updated_by: importData.updated_by || null
          });
          setLastImageUpdate(data.lastUpdate);
          setLastKnownPhotoCount(data.currentPhotoCount);
          return true;
        }
      }
      
      return false;
    } catch (error) {
      console.error('Error checking for image updates:', error);
      return false;
    }
  };

  const startPolling = () => {
    console.log('Starting polling with state:', {
      lastKnownPhotoCount,
      lastImageUpdate
    });
    
    // Clear any existing polling
    if (pollingTimeoutRef.current) {
      clearTimeout(pollingTimeoutRef.current);
    }
    
    setIsPolling(true);
    isPollingRef.current = true;
    pollStartTimeRef.current = Date.now();
    
    const poll = async () => {
      // First check timeout
      if (Date.now() - pollStartTimeRef.current > 180000) {
        console.log('Polling timeout reached');
        setIsPolling(false);
        isPollingRef.current = false;
        return;
      }

      try {
        const hasUpdates = await checkForImageUpdates();
        console.log('Poll result:', hasUpdates, 'isPolling:', isPollingRef.current);
        
        if (hasUpdates) {
          console.log('Updates found, stopping polling');
          setIsPolling(false);
          isPollingRef.current = false;
        } else if (isPollingRef.current) { // Check ref instead of state
          console.log('No updates yet, scheduling next poll...');
          pollingTimeoutRef.current = setTimeout(poll, 1000);
        } else {
          console.log('Polling was stopped, not scheduling next poll');
        }
      } catch (error) {
        console.error('Error during polling:', error);
        setIsPolling(false);
        isPollingRef.current = false;
      }
    };

    // Start the first poll
    poll();
  };

  const sendToDiscord = async () => {
    if (isProcessing) {
      console.log('Already processing a request');
      return;
    }

    setIsProcessing(true);
    
    try {
      // Stop any existing polling
      if (pollingTimeoutRef.current) {
        clearTimeout(pollingTimeoutRef.current);
      }
      setIsPolling(false);
      isPollingRef.current = false;

      const imageUrl = (item.item.images && item.item.images.length > 0) 
        ? item.item.images[0].large_url 
        : 'https://qph.cf2.quoracdn.net/main-qimg-654617264f9192ec976abe6e53356240-lq';

      const response = await fetch(`${DISCORD_SERVER_URL}/webhook/product-photo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          asin: item.item.asin,
          secret: 'Yyzprep123',
          imageUrl: imageUrl,
          title: item.item.title
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send to Discord');
      }

      // Reset states before starting new poll
      setLastKnownPhotoCount(0);
      setLastImageUpdate(null);
      
      // Small delay to ensure Discord bot has time to process
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      // Start polling with fresh state
      startPolling();
    } catch (error) {
      console.error('Error sending to Discord:', error);
      alert('Failed to send to Discord');
    } finally {
      setIsProcessing(false);
    }
  };

  // Update the cleanup effect
  useEffect(() => {
    return () => {
      if (pollingTimeoutRef.current) {
        clearTimeout(pollingTimeoutRef.current);
        pollingTimeoutRef.current = null;
      }
      setIsPolling(false);
      isPollingRef.current = false;
    };
  }, []);

  return (
    <div className="bg-white shadow-[0_0_10px_0_rgba(0,0,0,0.1)] rounded-lg mb-4 relative max-w-screen-lg mx-auto transition-shadow duration-200 hover:shadow-[0_0_20px_0_rgba(0,0,0,0.15)]">
      {/* Notification Badge */}
      <div className="absolute top-0 left-0 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-full">
        ✓ {priceNotification ? "Price Saved" : weightNotification ? "Weight Saved" : "Saved"}
      </div>

      {/* Header */}
      <div className="flex items-center justify-between p-3 border-b">
        <>
          <h3
            className="text-lg font-semibold"
            data-tip={item.item.title}
            data-for={`title-tooltip-${item.id}`}
          >
            {truncateText(item.item.title, 200)}
          </h3>
          <ReactTooltip
            id={`title-tooltip-${item.id}`}
            effect="solid"
            place="top"
            delayShow={200}
            delayHide={200}
            offset={{ top: 10, left: 10 }}
            className="max-w-xs"
          />
        </>
        {statusBadge}
      </div>

      {/* Item Details Row */}
      <div className="px-4 py-3 bg-gray-50 flex flex-wrap gap-4 text-sm">
        {/* Quantity */}
        <div className="flex items-center sm:mr-4">
          <span className="text-gray-500 mr-2 whitespace-nowrap">Quantity:</span>
          <span className="font-medium">{quantityInBoxes}</span>
          <span className="text-gray-400 mx-1">/</span>
          <span className="font-medium">{totalQuantity}</span>
        </div>

        {/* Weight */}
        <div className="flex items-center flex-1 min-w-[200px] sm:min-w-[140px] sm:flex-none">
          <span className="text-gray-500 mr-2 whitespace-nowrap">Weight:</span>
          <div className="relative flex-1 sm:flex-none">
            <input
              type="number"
              value={weight}
              onBlur={handleWeightBlur}
              onChange={(e) => setWeight(e.target.value)}
              className="w-full sm:w-20 px-2 py-1 border rounded focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
              placeholder="0.00"
            />
            <span className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400">kg</span>
          </div>
          {weightNotification && (
            <span className="ml-2 text-green-500 flex items-center">
              <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
              </svg>
            </span>
          )}
        </div>

        {/* Price (USA Only) */}
        {clientInfo.country === 'USA' && (
          <div className="flex items-center flex-1 min-w-[200px] sm:min-w-[140px] sm:flex-none">
            <span className="text-gray-500 mr-2 whitespace-nowrap">Price:</span>
            <div className="flex items-center space-x-2 flex-1 sm:flex-none">
              <button
                onClick={() => handlePriceCheck(item.item.asin)}
                disabled={isLoadingPrice}
                className={`
                  p-1.5 rounded transition-colors shrink-0
                  ${isLoadingPrice 
                    ? 'bg-gray-100 text-gray-400 cursor-wait' 
                    : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
                  }
                `}
              >
                {isLoadingPrice ? (
                  <svg className="animate-spin h-4 w-4" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                  </svg>
                ) : (
                  <span>$</span>
                )}
              </button>
              <input
                type="number"
                value={price}
                onBlur={handlePriceBlur}
                onChange={(e) => setPrice(e.target.value)}
                className="w-full sm:w-20 px-2 py-1 border rounded focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                placeholder="0.00"
              />
              {priceNotification && (
                <span className="text-green-500 shrink-0">
                  <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                  </svg>
                </span>
              )}
            </div>
          </div>
        )}

        {/* Expiry Date */}
        <div className="flex items-center flex-1 min-w-[200px] sm:min-w-[180px] sm:flex-none">
          <span className="text-gray-500 mr-2 whitespace-nowrap">Expiry:</span>
          <div className="flex items-center space-x-1 flex-1 sm:flex-none">
            <DatePicker
              selected={expiryDate}
              onChange={updateExpiryDate}
              className="w-full sm:w-28 px-2 py-1 border rounded focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
              placeholderText="Select date"
              dateFormat="yyyy-MM-dd"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              minDate={new Date(currentYear, 0, 1)}
              maxDate={new Date(maxYear, 11, 31)}
            />
            <button 
              onClick={() => setShowExpiryOptions(!showExpiryOptions)}
              className="p-1.5 rounded hover:bg-gray-200 relative shrink-0"
            >
              <svg className="w-4 h-4 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
              {showExpiryOptions && (
                <div className="absolute right-0 mt-1 w-40 bg-white rounded-md shadow-lg py-1 z-10">
                  {presetOptions.map((option, index) => (
                    <button
                      key={index}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={() => updateExpiryDate(option.value)}
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="p-4 grid grid-cols-12 gap-6">
        {/* Left Column - Image and Core Details */}
        <div className="col-span-12 sm:col-span-8 flex gap-4">
          {/* Image with Discord Button */}
          <div className="shrink-0 relative">
            <img
              src={(item.item.images && item.item.images.length > 0 && item.item.images[0].large_url) || 'https://qph.cf2.quoracdn.net/main-qimg-654617264f9192ec976abe6e53356240-lq'}
              alt={item.item.title}
              className="w-24 h-24 object-cover rounded-lg"
            />
            <button
              onClick={sendToDiscord}
              disabled={isProcessing}
              className={`absolute -top-2 -right-2 ${
                isProcessing 
                  ? 'bg-gray-400' 
                  : 'bg-[#5865F2] hover:bg-[#4752C4]'
              } text-white p-1.5 rounded-full shadow-lg transition-colors duration-200`}
              title={isProcessing ? "Processing..." : "Send to Discord"}
            >
              <FaDiscord className="w-4 h-4" />
            </button>
          </div>

          {/* Core Details */}
          <div className="flex-1 space-y-2 min-w-0">
            {/* ASIN Row */}
            <div className="flex items-center gap-6">
              <div className="flex items-center gap-2">
                <span className="text-gray-500 text-sm">ASIN:</span>
                <a
                  href={`https://${clientInfo.name.includes('(CA)') ? 'amazon.ca' : 'amazon.com'}/dp/${item.item.asin}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:text-blue-800"
                >
                  {item.item.asin}
                </a>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-gray-500 text-sm">FNSKU:</span>
                <span>{item.item.fnsku}</span>
              </div>
            </div>

            {/* SKU Row */}
            <div className="flex items-center gap-2">
              <span className="text-gray-500 text-sm">SKU:</span>
              <a
                href={`https://app.yyzprep.ca/staff/inventory/${item.item.id}#history`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:text-blue-800"
              >
                {item.item.merchant_sku}
              </a>
            </div>

            {/* Dimensions Row */}
            <div className="flex items-center gap-2">
              <span className="text-gray-500 text-sm">Dims:</span>
              <span>{formatDimensions(item.item.length_mm, item.item.width_mm, item.item.height_mm)}</span>
              <span className="text-gray-300">|</span>
              <span>{formatWeight(item.item.weight_gm)}</span>
            </div>

            {/* Prep Requirements Row */}
            <div className="flex items-center gap-2">
              <span className="text-gray-500 text-sm">Prep:</span>
              {isLoadingPrep ? (
                <div className="flex items-center">
                  <svg className="animate-spin h-4 w-4 text-blue-500 mr-2" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
                  </svg>
                  <span className="text-sm text-gray-500">Loading...</span>
                </div>
              ) : prepError ? (
                <span className="text-red-500 text-sm">{prepError}</span>
              ) : (
                <span className={`text-sm ${prepRequirements[item.item.merchant_sku]?.length > 0 ? "text-red-500" : "text-green-500"}`}>
                  {prepRequirements[item.item.merchant_sku]?.length > 0 ? prepRequirements[item.item.merchant_sku].join(', ') : 'None'}
                </span>
              )}
            </div>

            {/* Oversize Tag */}
            {oversizeTag && (
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                {oversizeTag}
              </span>
            )}
          </div>
        </div>

        {/* Right Column - Services */}
        <div className="col-span-12 sm:col-span-4">
          <div className="space-y-2">
            <label className="text-sm font-medium text-gray-700">Services Required</label>
            <Select
              isMulti
              options={serviceOptions}
              value={selectedServices}
              onChange={handleServiceChange}
              className="text-sm"
              classNamePrefix="select"
              placeholder="Add services..."
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: '36px',
                }),
                valueContainer: (base) => ({
                  ...base,
                  padding: '2px 8px',
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#EFF6FF',
                  borderRadius: '4px',
                  '.select__multi-value__label': {
                    color: '#1E40AF',
                  }
                })
              }}
            />
          </div>
        </div>
      </div>

      {/* Warning Message */}
      {warning && (
        <div className="mx-4 mb-4 flex items-center gap-2 text-sm text-red-600 bg-red-50 p-2 rounded">
          <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
          Expiry date is less than 90 days
        </div>
      )}

      {/* Shelving Locations (Conditional) */}
      {shelvingData && shelvingData.length > 0 && (
        <div className="p-3">
          <h4 className="text-lg font-semibold mb-2">Shelving Locations</h4>
          {shelvingData
            .filter(shelving => shelving.SKU === item.item.merchant_sku && shelvingInputValues[shelving.KEY] > 0)
            .map((shelving, index) => {
              const qtyToRemove = Math.min(quantityInBoxes, shelvingInputValues[shelving.KEY]);
              return (
                <div key={index} className="flex items-center justify-between mb-2">
                  <p><strong>Location:</strong> {shelving.FULL_SHELF_CODE}</p>
                  <div className="flex items-center space-x-2">
                    <input
                      type="number"
                      className="border border-gray-300 rounded p-1 w-16"
                      value={shelvingInputValues[shelving.KEY]}
                      onChange={(e) => handleShelvingQtyChange(shelving.KEY, Number(e.target.value))}
                      onBlur={() => handleShelvingQtyBlur(shelving.KEY, shelving.FULL_SHELF_CODE)}
                    />
                    <button
                      className="bg-red-500 text-white px-2 py-1 rounded"
                      onClick={() => {
                        if (window.confirm(`Are you sure you want to remove all items from ${shelving.FULL_SHELF_CODE}?`)) {
                          removeShelving(shelving.KEY, shelving.FULL_SHELF_CODE, item.item.merchant_sku);
                        }
                      }}
                    >
                      X
                    </button>
                    {shelvingInputValues[shelving.KEY] > 0 && (
                      <button
                        className="bg-blue-500 text-white px-2 py-1 rounded"
                        onClick={() => handleRemoveFromShelf(shelving.KEY, shelving.FULL_SHELF_CODE, qtyToRemove)}
                      >
                        Remove {qtyToRemove} pcs
                      </button>
                    )}
                    {removedQuantities[shelving.KEY] > 0 && (
                      <span className="text-green-500">
                        Removed {removedQuantities[shelving.KEY]} pcs
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}

      {/* USA Import Information */}
      {clientInfo.country === 'USA' && (
        <div className="border-t">
          {/* Header with Loading State */}
          <div className="flex items-center justify-between p-3">
            <h4 className="text-lg font-semibold text-gray-800">
              USA Import Information
            </h4>
            {isLoadingImportInfo && (
              <div className="flex items-center space-x-2">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-blue-500"></div>
                <span className="text-sm text-gray-500">Loading import data...</span>
              </div>
            )}
          </div>

          {!isLoadingImportInfo && (
            <div className="space-y-4 px-3">
              {/* Country of Origin and Photos Row */}
              <div className="grid grid-cols-2 gap-4">
                {/* Country of Origin Section */}
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <label className="text-sm font-semibold text-gray-700">
                      Country of Origin
                    </label>
                    {countrySaveNotification && (
                      <span className="text-green-500 text-sm font-medium flex items-center">
                        <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                        </svg>
                        Saved
                      </span>
                    )}
                  </div>
                  <CountrySelector
                    value={importInfo.countryOfOrigin}
                    onChange={handleCountryChange}
                    className="w-full"
                  />
                </div>

                {/* Origin Proof Photos Section */}
                <div>
                  <label className="text-sm font-semibold text-gray-700 block mb-2">
                    Origin Proof Photos
                  </label>
                  <div className="grid grid-cols-4 gap-2">
                    {importInfo.photos && Array.isArray(importInfo.photos) && importInfo.photos.map((photo, photoIndex) => (
                      <div 
                        key={photoIndex} 
                        className="relative group aspect-square cursor-pointer"
                        onClick={() => setDeletePhotoConfirm({ show: true, index: photoIndex })}
                      >
                        <img
                          src={photo.url || photo}
                          alt={`Origin proof ${photoIndex + 1}`}
                          className="w-full h-full object-cover rounded hover:opacity-90 transition-opacity duration-200"
                        />
                        {/* Overlay with delete icon */}
                        <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-25 transition-opacity duration-200 rounded" />
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10">
                          <svg className="w-6 h-6 text-white drop-shadow-lg" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                          </svg>
                        </div>
                      </div>
                    ))}
                    {/* Add photo button */}
                    <label className="aspect-square flex items-center justify-center border-2 border-dashed border-gray-300 rounded cursor-pointer hover:border-blue-500 hover:bg-blue-50 transition-colors duration-200">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) handlePhotoAdd(file);
                        }}
                        className="hidden"
                      />
                      <svg className="w-6 h-6 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                      </svg>
                    </label>
                  </div>
                </div>
              </div>

              {/* HTS Code Section */}
              <div>
                <div className="flex justify-between items-center mb-2">
                  <label className="text-sm font-semibold text-gray-700">
                    HTS Code
                  </label>
                  <button
                    onClick={getHtsSuggestions}
                    disabled={!isHtsEnabled || isLoadingHts}
                    className={`
                      inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium
                      transition-colors duration-200
                      ${isHtsEnabled 
                        ? isLoadingHts
                          ? 'bg-blue-50 text-blue-400 cursor-wait'
                          : 'bg-blue-50 text-blue-700 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500' 
                        : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      }
                    `}
                  >
                    {isLoadingHts ? (
                      <>
                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Processing...
                      </>
                    ) : (
                      <>
                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                        </svg>
                        AI Suggest
                      </>
                    )}
                  </button>
                </div>

                <div className={`${isHtsEnabled ? '' : 'opacity-50'} mb-3`}>
                  <input
                    type="text"
                    value={importInfo.htsCode || ''}
                    onChange={(e) => handleHtsChange(e.target.value)}
                    placeholder="Enter HTS Code"
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                    disabled={!isHtsEnabled}
                  />
                  
                  {!isHtsEnabled && (
                    <div className="flex items-center text-sm text-gray-500 mt-1">
                      <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      HTS Code only required for items from China
                    </div>
                  )}
                </div>

                {/* HTS Suggestions Display */}
                {!isLoadingHts && htsSuggestions && (
                  <div className="mt-2 space-y-2">
                    {/* Primary Match */}
                    {htsSuggestions.matches?.[0] && (
                      <div className="bg-blue-50 p-2 rounded">
                        <div className="flex justify-between items-start mb-1">
                          <span className="text-sm font-semibold text-blue-800">Primary Match</span>
                          {htsSuggestions.confidence && (
                            <span className="px-2 py-0.5 bg-blue-100 text-blue-800 text-xs font-medium rounded-full">
                              {Math.round(htsSuggestions.confidence * 100)}% match
                            </span>
                          )}
                        </div>
                        <div 
                          className="text-sm text-blue-900"
                          dangerouslySetInnerHTML={{ 
                            __html: htsSuggestions.matches[0].description 
                          }}
                        />
                        <div className="mt-1 flex flex-wrap gap-1">
                          <span className="px-2 py-0.5 bg-white text-blue-800 text-xs font-medium rounded-full">
                            HTS: {htsSuggestions.matches[0].HTS_id}
                          </span>
                          {htsSuggestions.matches[0].action_description && (
                            <span className="px-2 py-0.5 bg-red-100 text-red-800 text-xs font-medium rounded-full">
                              {htsSuggestions.matches[0].action_description}
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    {/* Duties Information */}
                    {htsSuggestions.duties && (
                      <div className="bg-red-50 p-2 rounded text-sm">
                        <h5 className="font-semibold text-red-800 mb-1">Duties Information</h5>
                        {htsSuggestions.duties.section301 && (
                          <div className="flex items-center text-red-700">
                            <span className="font-medium mr-2">Section 301:</span>
                            {htsSuggestions.duties.section301}
                          </div>
                        )}
                        {htsSuggestions.duties.feb2025Tariff && (
                          <div className="flex items-center text-red-700">
                            <span className="font-medium mr-2">Feb 2025 Tariff:</span>
                            {htsSuggestions.duties.feb2025Tariff}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Print Buttons */}
      <div className="flex justify-center space-x-4 p-3 bg-gray-100 rounded-b-lg">
        <button
          className="bg-blue-500 text-white py-1 px-3 rounded"
          onClick={() => handlePrint(1)}
        >
          Print 1
        </button>
        <button
          className="bg-blue-500 text-white py-1 px-3 rounded"
          onClick={() => handlePrint(5)}
        >
          Print 5
        </button>
        <div className="relative">
          <button
            className="bg-blue-500 text-white py-1 px-3 rounded"
            onClick={() => togglePrintOptions(setShowPrintOptionsCustom)}
          >
            Print
          </button>
          <input
            type="number"
            id={`customQty-${item.item.merchant_sku}`}
            className="border border-gray-300 rounded p-1 w-16 ml-2"
            value={customQty}
            onChange={(e) => setCustomQty(e.target.value)}
            placeholder="Qty"
          />
          {showPrintOptionsCustom && (
            <div ref={customPrintDropdownRef} className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
              <div className="py-1">
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    const qty = parseInt(document.getElementById(`customQty-${item.item.merchant_sku}`).value, 10);
                    handlePrintOptionChange(qty, '', setShowPrintOptionsCustom);
                  }}
                >
                  Print
                </button>
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    const qty = parseInt(document.getElementById(`customQty-${item.item.merchant_sku}`).value, 10);
                    handlePrintOptionChange(qty, 'Ready to Ship', setShowPrintOptionsCustom);
                  }}
                >
                  Print with Ready to Ship
                </button>
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    const qty = parseInt(document.getElementById(`customQty-${item.item.merchant_sku}`).value, 10);
                    handlePrintOptionChange(qty, 'Sold as Set', setShowPrintOptionsCustom);
                  }}
                >
                  Print with Sold as Set
                </button>
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => {
                    const qty = parseInt(document.getElementById(`customQty-${item.item.merchant_sku}`).value, 10);
                    handlePrintOptionChange(qty, 'Warning Labels', setShowPrintOptionsCustom);
                  }}
                >
                  Print with Warning Labels
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deletePhotoConfirm.show}
        onClose={() => setDeletePhotoConfirm({ show: false, index: null })}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle className="bg-gray-50">
          <div className="flex items-center">
            <svg className="w-5 h-5 text-red-500 mr-2" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
            Delete Photo
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="mt-2">
            Are you sure you want to delete this origin proof photo? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions className="bg-gray-50">
          <Button 
            onClick={() => setDeletePhotoConfirm({ show: false, index: null })}
            className="text-gray-600"
          >
            Cancel
          </Button>
          <Button 
            onClick={() => handlePhotoDelete(deletePhotoConfirm.index)} 
            color="error"
            variant="contained"
            className="bg-red-500 hover:bg-red-600"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default OutboundItem;
