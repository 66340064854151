import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { USAContext } from "./USAContext";
import { saveAs } from "file-saver";
import { PDFDocument, rgb } from "pdf-lib";
import {fetchUpsMergedLabels} from "./MergeUPSLabels"
const API_URL = process.env.REACT_APP_API_URL;

const PurchaseLabels = () => {
  const { shipmentInfo, clientsData, boxes, asinData, selectedPlan } =
    useContext(USAContext);

  const [credits, setCredits] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [clientName, setClientName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [requiredCredits, setRequiredCredits] = useState(0);
  const [purchaseAmount, setPurchaseAmount] = useState("");
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [purchaseComplete, setPurchaseComplete] = useState(false);
  const [stallionPdfBytes, setStallionPdfBytes] = useState(null);
  const [upsPdfUrl, setUpsPdfUrl] = useState("");
  const [testMode, setTestMode] = useState(false); // Set test mode to true
  const [adjustedAddresses, setAdjustedAddresses] = useState({}); // Store adjusted addresses from the database
  const [refreshUpsLabels, setRefreshUpsLabels] = useState(false); // State to trigger UPS label refresh
  const [upsLabelsLoading, setUpsLabelsLoading] = useState(false);
  const [upsFile, setUpsFile] = useState(null);
  const [stallionFile, setStallionFile] = useState(null);
  const [importInfo, setImportInfo] = useState({});
  
  const location = useLocation();
  const navigate = useNavigate();

  // Fetch UPS labels on component mount or when selectedPlan changes
  useEffect(() => {
    if (selectedPlan && selectedPlan.placement_options) {
      console.log("Getting earliest placement option...");
      const earliestConfirmedOption = selectedPlan.placement_options.reduce(
        (earliest, option) => {
          if (
            option.confirmed_at &&
            (!earliest || new Date(option.confirmed_at) < new Date(earliest.confirmed_at))
          ) {
            return option;
          }
          return earliest;
        },
        null
      );

      if (earliestConfirmedOption) {
        console.log("Found earliest placement option.");
        //fetchAndMergeUPSLabels(earliestConfirmedOption, selectedPlan);
        //handleDownloadUPSLabels(earliestConfirmedOption);
      } else {
        console.error("No confirmed placement option found.");
      }
    }
  }, [selectedPlan, refreshUpsLabels]); // Add refreshUpsLabels as a dependency

  // Fetch API key and credits if not in test mode
  useEffect(() => {
    if (!testMode && shipmentInfo && clientsData) {
      const client = clientsData.find(
        (client) => client.id === shipmentInfo.team_id
      );
      if (client) {
        fetchApiKey(client.name);
      } else {
        console.log("No client found for team_id:", shipmentInfo.team_id);
      }
    } else if (!testMode) {
      console.log("shipmentInfo or clientsData not available yet");
    }
  }, [shipmentInfo, clientsData, testMode]);

  // Calculate required credits when boxes change
  useEffect(() => {
    if (boxes) {
      calculateRequiredCredits(boxes);
      console.log("Selected Plan:", selectedPlan);
    }
  }, [boxes, selectedPlan]);

  // Fetch adjusted addresses from the database
  useEffect(() => {
    if (selectedPlan) {
      fetchAdjustedAddresses();
    }
  }, [selectedPlan]);

  // Fetch adjusted addresses from the database
  const fetchAdjustedAddresses = async () => {
    try {
      const response = await fetch(`${API_URL}/api/warehouse-addresses`);
      const data = await response.json();
      const adjustedAddressesMap = {};
      data.forEach(address => {
        adjustedAddressesMap[address.warehouse_id] = address;
      });
      setAdjustedAddresses(adjustedAddressesMap);
    } catch (error) {
      console.error('Error fetching adjusted addresses:', error);
    }
  };

  // Calculate required credits based on box weights
  const calculateRequiredCredits = (boxes) => {
    let runningTotal = 0;
    boxes.forEach((box) => {
      const weightLbs = box.weight * 2.20462; // Convert kg to lbs
      let amount = 0;

      if (weightLbs < 10) {
        amount = 3.99;
      } else if (weightLbs >= 10 && weightLbs < 20) {
        amount = 5.99;
      } else if (weightLbs >= 20 && weightLbs < 40) {
        amount = 9.99;
      } else {
        amount = 12.99;
      }

      runningTotal += amount;
    });

    setRequiredCredits(parseFloat(runningTotal.toFixed(2)));
  };

  // Fetch API key for the client
  const fetchApiKey = async (clientName) => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/api/getStallionApiKey`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ clientName }),
      });
      const data = await response.json();

      if (data.apiKey) {
        setApiKey(data.apiKey);
        setClientName(clientName);
        fetchCredits(data.apiKey);
        setError("");
      } else {
        setError("API key not found for client: " + clientName);
      }
    } catch (error) {
      console.error("Error fetching API key:", error);
      setError("Failed to fetch API key. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch available credits
  const fetchCredits = async (apiKey) => {
    if (testMode) return; // Skip credit fetching in test mode
    try {
      const creditsResponse = await fetch(
        "https://ship.stallionexpress.ca/api/v3/credits",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      const creditsData = await creditsResponse.json();
      setCredits(parseFloat(creditsData.credits));
    } catch (error) {
      console.error("Error fetching credits:", error);
      setError("Failed to fetch credits. Please try again.");
    }
  };

  // Get price for an ASIN
  const getPrice = (asin) => {
    const data = asinData ? asinData.find((item) => item.ASIN === asin) : null;
    return data ? parseFloat(data.VALUE) : 0;
  };

  // Calculate the total value of a box
  const calculateBoxValue = (box) => {
    return box.items.reduce((acc, item) => {
      return acc + getPrice(item.ASIN) * item.QTY;
    }, 0);
  };

  // Handle changes to the purchase amount input
  const handlePurchaseAmountChange = (event) => {
    setPurchaseAmount(event.target.value);
  };

  // Update purchase amount based on credits and required credits
  useEffect(() => {
    if (!testMode && credits !== null && requiredCredits !== null) {
      const difference = Math.max(10, requiredCredits - credits);
      setPurchaseAmount(difference > 0 ? difference.toFixed(2) : "");
    }
  }, [credits, requiredCredits, testMode]);

  // Handle purchasing credits (only in production mode)
  const handlePurchaseCredits = async () => {
    if (testMode) {
      // In test mode, skip purchasing and just clear any error
      setError("");
      return;
    }

    if (parseFloat(purchaseAmount) < 10) {
      setError("Minimum purchase amount is $10");
      return;
    }

    setPurchaseLoading(true);
    try {
      const purchaseResponse = await fetch(
        "https://ship.stallionexpress.ca/api/v3/credits",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({ amount: parseFloat(purchaseAmount).toFixed(2) }),
        }
      );

      const purchaseData = await purchaseResponse.json();
      if (purchaseData.success) {
        fetchCredits(apiKey);
        setPurchaseAmount("");
      } else {
        setError("Failed to purchase credits. Please try again.");
      }
    } catch (error) {
      console.error("Error purchasing credits:", error);
      setError("Failed to purchase credits. Please try again.");
    } finally {
      setPurchaseLoading(false);
    }
  };

  // Modify the fetchImportInfo function to only fetch data
  const fetchImportInfo = async (asins) => {
    try {
      console.log('Fetching import info for ASINs:', asins);
      
      const response = await fetch(`${API_URL}/api/usa-import-info/batch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ asins }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Raw API Response:', data); // Debug log for raw response

      // Ensure data is in the correct format
      const formattedData = {};
      if (Array.isArray(data)) {
        data.forEach(item => {
          if (item.asin) {
            formattedData[item.asin] = {
              country_of_origin: item.country_of_origin,
              hts_code: item.hts_code
            };
          }
        });
      } else if (typeof data === 'object') {
        // If data is already in the correct format, use it directly
        Object.assign(formattedData, data);
      }

      console.log('Formatted import info:', formattedData); // Debug log for formatted data
      setImportInfo(formattedData);

    } catch (error) {
      console.error('Error fetching import info:', error);
      setError(`Failed to fetch product import information: ${error.message}`);
    }
  };

  // Update the useEffect to trigger on more dependencies
  useEffect(() => {
    if (boxes && selectedPlan) {
      // Get unique ASINs from all boxes
      const asins = [...new Set(boxes.flatMap(box => 
        box.items.map(item => item.ASIN)
      ))];
      
      console.log('Fetching import info for ASINs:', asins); // Debug log
      fetchImportInfo(asins);
    }
  }, [boxes, selectedPlan]); // Add selectedPlan as dependency

  // Modify the handlePurchaseLabels function to include import info in API call
  const handlePurchaseLabels = async () => {
    setPurchaseLoading(true);
    try {
      let labels = [];
      const pdfDoc = await PDFDocument.create();

      const totalBoxes = boxes?.length || 0;
      if (!boxes || !boxes.length) {
        setError("No boxes available.");
        setPurchaseLoading(false);
        return;
      }

      // Find the earliest confirmed placement option
      const earliestConfirmedOption = selectedPlan.placement_options.reduce(
        (earliest, option) => {
          if (
            option.confirmed_at &&
            (!earliest || new Date(option.confirmed_at) < new Date(earliest.confirmed_at))
          ) {
            return option;
          }
          return earliest;
        },
        null
      );

      if (!earliestConfirmedOption) {
        console.error("No confirmed placement option found.");
        setPurchaseLoading(false);
        return;
      }

      // In test mode, load Stallion labels from JSON
      if (testMode) {
        console.log("Loading Stallion labels from JSON file...");
        const response = await fetch(`${API_URL}/api/getStallionLabels`);
        const data = await response.json();
        console.log("Using JSON File for Stallion Labels...");
        
        // **Fetch UPS PDF to determine page size (only once)**
        let pageWidth = 8.5 * 72; // Default to Letter size in case fetch fails
        let pageHeight = 11 * 72; // Default to Letter size
        
        try {
          const upsPdfResponse = await fetch(upsPdfUrl); // Ensure upsPdfUrl is defined and accessible
          if (!upsPdfResponse.ok) {
            throw new Error("Failed to fetch UPS PDF");
          }
          const upsPdfBytes = await upsPdfResponse.arrayBuffer();
          const upsPdf = await PDFDocument.load(upsPdfBytes);
          const firstUpsPage = upsPdf.getPages()[0];
          const upsPageSize = firstUpsPage.getSize();
          pageWidth = upsPageSize.width;
          pageHeight = upsPageSize.height;
        } catch (error) {
          console.error("Error fetching UPS PDF for determining page size:", error);
          setError("Failed to fetch UPS PDF for determining page size. Using default page size.");
        }
        
        for (const [index, box] of boxes.entries()) {
          const labelData = data[index];
          if (labelData && labelData.success && labelData.label) {
            labels.push({
              label: "base64-embedded",
              tracking_code:
                labelData.shipment && labelData.shipment.tracking_code
                  ? labelData.shipment.tracking_code
                  : "",
              box_number: index + 1,
            });
        
            // Decode the base64 label (PNG)
            const pngBytes = Uint8Array.from(
              atob(labelData.label),
              (char) => char.charCodeAt(0)
            );
        
            // **Define page size to match UPS labels**
            const page = pdfDoc.addPage([pageWidth, pageHeight]);
        
            // Embed PNG into the PDF
            const pngImage = await pdfDoc.embedPng(pngBytes);
            const pngDims = pngImage.scale(1);
        
            // **Calculate scaling to fit the image within UPS label size while maintaining aspect ratio**
            const scale = Math.min(pageWidth / pngDims.width, pageHeight / pngDims.height);
            const scaledWidth = pngDims.width * scale;
            const scaledHeight = pngDims.height * scale;
        
            // **Center the image on the page**
            page.drawImage(pngImage, {
              x: (pageWidth - scaledWidth) / 2, // Center horizontally
              y: (pageHeight - scaledHeight) / 2, // Center vertically
              width: scaledWidth,
              height: scaledHeight,
            });
        
            // Add box number annotation to the last page (adjust position if necessary)
            const lastPage = pdfDoc.getPages()[pdfDoc.getPageCount() - 1];
            lastPage.drawText(`B${index + 1}o${totalBoxes}`, {
              x: 0.12 * 72, // Adjust X position as needed
              y: 4 * 72, // Adjust Y position as needed
              size: 14,
              color: rgb(0, 0, 0),
            });
          } else {
            console.error(`No label found in JSON for index: ${index}`);
          }
        }
      }
        
      else {
        // Production mode: Actual API call to purchase labels
        for (const [index, box] of boxes.entries()) {
          const boxValue = calculateBoxValue(box);

          // Find the shipment for the current box
          const shipment = findShipmentForBox(box);
          if (!shipment) {
            console.error(`No shipment found for box number: ${box.boxNumber}`);
            continue;
          }

          // Use adjusted address if available, otherwise use shipment address
          const adjustedAddress = adjustedAddresses[shipment.warehouse_id];
          const addressToUse = adjustedAddress
            ? {
                address1: adjustedAddress.address_line_1,
                address2: adjustedAddress.address_line_2 || "",
                city: adjustedAddress.city,
                province_code: adjustedAddress.state_or_province_code,
                postal_code: adjustedAddress.postal_code,
                country_code: shipment.address.country_code,
              }
            : {
                address1: shipment.address.address_line_1,
                address2: shipment.address.address_line_2 || "",
                city: shipment.address.city,
                province_code: shipment.address.state_or_province_code,
                postal_code: shipment.address.postal_code,
                country_code: shipment.address.country_code,
              };

          const response = await fetch(
            "https://ship.stallionexpress.ca/api/v3/shipments",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${apiKey}`,
              },
              body: JSON.stringify({
                name: clientName.substring(0, 40),
                ...addressToUse,
                weight_unit: "kg",
                weight: parseFloat(box.weight),
                length: parseFloat(box.length),
                width: parseFloat(box.width),
                height: parseFloat(box.height),
                size_unit: "cm",
                items: box.items.map((item) => ({
                  currency: "USD",
                  description: item.TITLE.slice(0, 40),
                  quantity: item.QTY,
                  sku: item.ASIN,
                  value: getPrice(item.ASIN),
                  country_of_origin: importInfo[item.ASIN]?.country_of_origin || '',
                  ...(importInfo[item.ASIN]?.country_of_origin === 'CN' && importInfo[item.ASIN]?.hts_code && { 
                    hs_code: importInfo[item.ASIN].hts_code 
                  })
                })),
                value: boxValue,
                currency: "USD",
                package_type: "parcel",
                postage_type: "ups_standard",
                signature_confirmation: false,
                label_format: "png",
                purchase_label: true,
                is_fba: true,
              }),
            }
          );
          const responseData = await response.json();
          await saveStallionResponse(responseData);

          if (responseData.success) {
            labels.push({
              label: responseData.label_url,
              tracking_code: responseData.tracking_code,
              box_number: box.boxNumber,
            });

            const labelPdfResponse = await fetch(responseData.label_url);
            const labelPdfBytes = await labelPdfResponse.arrayBuffer();
            const labelPdfDoc = await PDFDocument.load(labelPdfBytes);
            const copiedPages = await pdfDoc.copyPages(
              labelPdfDoc,
              labelPdfDoc.getPageIndices()
            );
            copiedPages.forEach((page) => {
              pdfDoc.addPage(page);
            });

            const lastPage = pdfDoc.getPages()[pdfDoc.getPageCount() - 1];
            lastPage.drawText(`B${index + 1} of ${totalBoxes}`, {
              x: 0.12 * 72, // Adjust X position as needed
              y: 4.25 * 72, // Adjust Y position as needed
              size: 14,
              color: rgb(0, 0, 0),
            });
          } else {
            setError("Failed to purchase labels for one or more boxes.");
            console.error("Error purchasing label:", responseData.message);
          }
        }
      }

      setLabels(labels);
      setPurchaseComplete(true);

      const pdfBytes = await pdfDoc.save();
      setStallionPdfBytes(pdfBytes);
      saveAs(
        new Blob([pdfBytes], { type: "application/pdf" }),
        `USA_Labels_${selectedPlan.id}_${selectedPlan.name}.pdf`
      );

      setPurchaseLoading(false);
    } catch (error) {
      console.error("Error purchasing labels:", error);
      setError("Failed to purchase labels. Please try again.");
      setPurchaseLoading(false);
    }
  };

  // Function to save Stallion API responses (for logging or future use)
  const saveStallionResponse = async (responses) => {
    try {
      await fetch(`${API_URL}/api/saveStallionResponse`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ responseData: responses }),
      });
      console.log("Stallion response data saved successfully.");
    } catch (error) {
      console.error("Error saving Stallion response data:", error);
    }
  };


  const handleMergeUploadedLabels = async () => {
    if (!upsFile || !stallionFile) {
      console.error("Both UPS and Stallion PDF files must be uploaded.");
      return;
    }
  
    try {
      // Read the UPS file as arrayBuffer
      const upsArrayBuffer = await upsFile.arrayBuffer();
      const upsPdf = await PDFDocument.load(upsArrayBuffer);
      const upsPages = upsPdf.getPages();
  
      // Read the Stallion file as arrayBuffer
      const stallionArrayBuffer = await stallionFile.arrayBuffer();
      const stallionPdf = await PDFDocument.load(stallionArrayBuffer);
      const stallionPages = stallionPdf.getPages();
  
      // Create a new PDF to merge
      const mergedPdf = await PDFDocument.create();
  
      // Copy pages from both PDFs into mergedPdf
      const upsCopiedPages = await mergedPdf.copyPages(upsPdf, upsPdf.getPageIndices());
      const stallionCopiedPages = await mergedPdf.copyPages(stallionPdf, stallionPdf.getPageIndices());
  
      let upsPageIndex = 0;
      let stallionPageIndex = 0;
  
      // Insert a Stallion page after every 2 UPS pages
      while (upsPageIndex < upsCopiedPages.length) {
        // Add two UPS pages or until we run out of UPS pages
        for (let i = 0; i < 2 && upsPageIndex < upsCopiedPages.length; i++) {
          mergedPdf.addPage(upsCopiedPages[upsPageIndex]);
          upsPageIndex++;
        }
  
        // Add one Stallion page if available
        if (stallionPageIndex < stallionCopiedPages.length) {
          mergedPdf.addPage(stallionCopiedPages[stallionPageIndex]);
          stallionPageIndex++;
        }
      }
  
      // If any Stallion pages remain, add them
      while (stallionPageIndex < stallionCopiedPages.length) {
        mergedPdf.addPage(stallionCopiedPages[stallionPageIndex]);
        stallionPageIndex++;
      }
  
      // Save and download the merged PDF
      const mergedPdfBytes = await mergedPdf.save();
      saveAs(
        new Blob([mergedPdfBytes], { type: "application/pdf" }),
        `Merged_Labels.pdf`
      );
    } catch (error) {
      console.error("Error merging uploaded labels:", error);
      setError("Failed to merge uploaded labels. Please try again.");
    }
  };
  

  const handleDownloadUPSLabels = async () => {
    try {
      // Call fetchAndMergeUPSLabels to get the merged PDF
      setUpsLabelsLoading(true)
      const confirmedPlacementOption = selectedPlan.placement_options.find(option => option.confirmed_at !== null);
      await fetchUpsMergedLabels(confirmedPlacementOption, selectedPlan.id);
      setUpsLabelsLoading(false)
    } catch (error) {
      setUpsLabelsLoading(false)
      console.error("Error downloading UPS labels:", error);
      setError("Failed to download UPS labels. Please try again.");
    }
  };



// Helper function to find the shipment for a given box
const findShipmentForBox = (box) => {
  const boxNumber = parseInt(box.boxNumber, 10);

  // 1. Filter out placement options that are not confirmed
  const confirmedPlacementOptions = selectedPlan.placement_options.filter(
    (po) => po.confirmed_at !== null
  );

  if (confirmedPlacementOptions.length === 0) {
    console.error("No placement options with confirmed_at found.");
    return null;
  }

  // 2. Sort placement options by their confirmed date (earliest first)
  confirmedPlacementOptions.sort(
    (a, b) => new Date(a.confirmed_at) - new Date(b.confirmed_at)
  );

  // 3. Take the earliest confirmed option
  const earliestConfirmedOption = confirmedPlacementOptions[0];

  // 4. Within this option, find the shipment whose .boxes array contains the matching box_number
  const matchingShipment = earliestConfirmedOption.shipments.find((shipment) =>
    shipment.boxes.some((b) => parseInt(b.box_number, 10) === boxNumber)
  );

  if (!matchingShipment) {
    console.error(`Shipment not found for box number: ${boxNumber}`);
    return null;
  }

  // 5. Return the .destination of the matching shipment
  return matchingShipment.destination;
};


//   // Fetch and merge UPS labels
//   const fetchAndMergeUPSLabels = async (earliestConfirmedOption, plan) => {
//     console.log("Fetching UPS Labels...");
//     try {
//         // Create a new PDF document to merge labels
//         const mergedPdf = await PDFDocument.create();

//         // Step 1: Build a box_group map from box_group_id to actual box_numbers
//         console.log("Building box_group map...");
//         const boxGroupMap = plan.box_groups.reduce((map, boxGroup) => {
//             map[boxGroup.id] = boxGroup.box_numbers;
//             return map;
//         }, {});
//         console.log("Box Group Map:", boxGroupMap);

//         // Step 2: Get shipments and determine their box_numbers
//         console.log("Getting shipments with box numbers...");
//         const shipmentsWithBoxNumbers = earliestConfirmedOption.shipments.map(shipment => {
//             const boxNumbers = shipment.box_groups.flatMap(boxGroup => boxGroupMap[boxGroup.id] || []);
//             return {
//                 shipment_id: shipment.shipment_id,
//                 box_numbers: boxNumbers
//             };
//         }).filter(shipment => shipment.box_numbers.length > 0); // Filter out shipments with no box numbers
//         console.log("Shipments with Box Numbers:", shipmentsWithBoxNumbers);

//         if (!shipmentsWithBoxNumbers.length) {
//             console.log("No shipments with box numbers found.");
//             return;
//         }

//         // Step 3: Collect all box numbers across all shipments
//         console.log("Collecting all box numbers...");
//         const allBoxNumbers = shipmentsWithBoxNumbers.flatMap(shipment => shipment.box_numbers);
//         console.log("All Box Numbers:", allBoxNumbers);

//         // Step 4: Sort all box numbers in ascending order
//         console.log("Sorting all box numbers...");
//         const sortedBoxNumbers = [...new Set(allBoxNumbers)].sort((a, b) => a - b);
//         console.log("Sorted Box Numbers:", sortedBoxNumbers);

//         // Step 5: Fetch and collect all package labels in sorted order of box numbers
//         console.log("Fetching and collecting package labels...");
//         for (const boxNumber of sortedBoxNumbers) {
//             // Find the shipment that contains the current box number
//             const shipmentInfo = shipmentsWithBoxNumbers.find(shipment => shipment.box_numbers.includes(boxNumber));
//             if (!shipmentInfo) {
//                 console.warn(`No shipment found for box number: ${boxNumber}`);
//                 continue;
//             }
//             const shipmentId = shipmentInfo.shipment_id;

//             console.log(`Fetching labels for shipment ID: ${shipmentId}, Box Number: ${boxNumber}`);

//             // Fetch UPS labels for the shipment
//             const response = await fetch(
//                 `https://app.yyzprep.ca/api/fba-transport/v2024/plans/${plan.id}/labels?shipment_id=${shipmentId}&api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`
//             );

//             if (!response.ok) {
//                 console.error(`Failed to fetch labels for shipment ID: ${shipmentId}, Box Number: ${boxNumber}`);
//                 continue;
//             }

//             const data = await response.json();
//             console.log(`Labels data for shipment ID: ${shipmentId}, Box Number: ${boxNumber}`, data);

//             // Find the 4" x 6" package label
//             const label = data.data?.find(
//                 (label) => label.name.includes('4" x 6"') && label.type === 'package'
//             );

//             if (!label || !label.url) {
//                 console.warn(`No 4" x 6" package label found for shipment ID: ${shipmentId}, Box Number: ${boxNumber}`);
//                 continue;
//             }

//             // Fetch the PDF via the backend proxy
//             const proxyUrl = `${API_URL}/api/fetch-pdf?url=${encodeURIComponent(label.url)}`;
//             const upsPdfResponse = await fetch(proxyUrl);

//             if (!upsPdfResponse.ok) {
//                 console.error(`Failed to fetch PDF for shipment ID: ${shipmentId}, Box Number: ${boxNumber}`);
//                 continue;
//             }

//             const upsPdfBytes = await upsPdfResponse.arrayBuffer();
//             const upsPdf = await PDFDocument.load(upsPdfBytes);

//             // Extract labels for the current box number
//             const pages = upsPdf.getPages();
//             const boxNumberIndex = shipmentInfo.box_numbers.indexOf(boxNumber);
//             const startPageIndex = boxNumberIndex * 2; // Each box number has 2 pages

//             // Check if the pages exist before copying
//             if (startPageIndex + 1 >= pages.length) {
//                 console.warn(`Not enough pages in PDF for shipment ID: ${shipmentId}, Box Number: ${boxNumber}`);
//                 continue;
//             }

//             // Copy the pages for the current box number
//             const pagesToCopy = await mergedPdf.copyPages(
//                 upsPdf,
//                 [startPageIndex, startPageIndex + 1]
//             );

//             console.log(`Adding pages for box number: ${boxNumber} (Shipment ID: ${shipmentId})`);
//             pagesToCopy.forEach(page => {
//                 mergedPdf.addPage(page);
//             });
//         }

//         // Step 6: Save and download the merged PDF
//         console.log("Saving and downloading the merged PDF...");
//         const mergedPdfBytes = await mergedPdf.save();
//         saveAs(
//             new Blob([mergedPdfBytes], { type: "application/pdf" }),
//             `UPS_Labels_${plan.id}_${plan.name}.pdf`
//         );
//     } catch (error) {
//         console.error("Error fetching and merging UPS labels:", error);
//     }
// };
// Handle downloading merged Stallion + UPS labels
const handleDownloadMergedLabels = async () => {
  if (!stallionPdfBytes || !upsPdfUrl) {
    console.error("Stallion or UPS labels not ready");
    setError("Labels are not ready yet. Please try again later.");
    return;
  }

  try {
    const mergedPdf = await PDFDocument.create();

    // Load Stallion PDF
    const stallionPdf = await PDFDocument.load(stallionPdfBytes);
    const stallionPages = await mergedPdf.copyPages(
      stallionPdf,
      stallionPdf.getPageIndices()
    );

    // Load UPS PDF
    const upsPdfResponse = await fetch(upsPdfUrl);
    if (!upsPdfResponse.ok) {
      console.error("Failed to fetch UPS PDF for merging");
      setError("Failed to fetch UPS labels for merging.");
      return;
    }
    const upsPdfBytesFetched = await upsPdfResponse.arrayBuffer();
    const upsPdf = await PDFDocument.load(upsPdfBytesFetched);
    const upsPages = await mergedPdf.copyPages(
      upsPdf,
      upsPdf.getPageIndices()
    );

    // Merge PDFs with Stallion labels after every 2 UPS pages
    let stallionPageIndex = 0;
    let upsPageIndex = 0;

    while (upsPageIndex < upsPages.length) {
      // Add UPS page
      mergedPdf.addPage(upsPages[upsPageIndex]);
      upsPageIndex++;

      // **Updated Condition: Insert Stallion every 2 UPS pages**
      if (upsPageIndex % 2 === 0 && upsPageIndex !== 0 && stallionPageIndex < stallionPages.length) {
        mergedPdf.addPage(stallionPages[stallionPageIndex]);
        stallionPageIndex++;
      }
    }

    // Add any remaining Stallion pages
    while (stallionPageIndex < stallionPages.length) {
      mergedPdf.addPage(stallionPages[stallionPageIndex]);
      stallionPageIndex++;
    }

    // Save and download the merged PDF
    const mergedPdfBytes = await mergedPdf.save();
    saveAs(
      new Blob([mergedPdfBytes], { type: "application/pdf" }),
      `MERGED_Labels_${selectedPlan.id}_${selectedPlan.name}.pdf`
    );
  } catch (error) {
    console.error("Error merging labels:", error);
    setError("Failed to merge labels. Please try again.");
  }
};

// Determine if labels can be purchased
const canPurchaseLabels = () => {
  if (testMode) {
    return !purchaseLoading; // Always enabled in test mode, except when loading
  }
  return credits !== null && credits >= requiredCredits && !purchaseLoading;
};

// Determine if merged labels can be downloaded
const canDownloadMergedLabels = () => {
  if (testMode) {
    // In test mode, enable if we have completed purchase and have stallionPdfBytes and upsPdfUrl
    return purchaseComplete && stallionPdfBytes && upsPdfUrl;
  }
  return purchaseComplete && upsPdfUrl && stallionPdfBytes && !purchaseLoading;
};

return (
  <Container>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">Purchase Labels</Typography>
        <Paper style={{ padding: "16px", marginBottom: "8px" }}>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>

            
          ) : (
            <>
              {testMode ? (
                <>
                  <Typography variant="h6" color="primary">
                    Test Mode Enabled
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    In test mode, credits are not fetched or required.
                  </Typography>
                  <Box display="flex" alignItems="center" marginTop={2}>
                    <TextField
                      label="Purchase Credits (Test Mode)"
                      value={purchaseAmount}
                      onChange={handlePurchaseAmountChange}
                      helperText="No actual purchase required."
                      style={{ width: "180px", marginRight: "16px" }}
                      disabled
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePurchaseLabels}
                      disabled={purchaseLoading} // Always enabled in test mode
                    >
                      {purchaseLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Purchase Labels (Test)"
                      )}
                    </Button>
                  </Box>
                  <Box display="flex" justifyContent="space-between" marginTop={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePurchaseLabels}
                      disabled={purchaseLoading} // Always enabled in test mode
                    >
                      {purchaseLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Purchase Labels (Test)"
                      )}
                    </Button>
                    
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleDownloadMergedLabels}
                      disabled={!canDownloadMergedLabels()}
                    >
                      {purchaseLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Download Stallion + UPS Labels (Test)"
                      )}
                    </Button>
                  </Box>
                </>
              ) : credits !== null ? (
                <>
                  <Typography variant="h6">
                    API Key Found for client: {clientName}
                  </Typography>
                  <Typography variant="body1">
                    Available Credits: ${credits}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      color: credits >= requiredCredits ? "green" : "red",
                    }}
                  >
                    Required Credits: ${requiredCredits}
                  </Typography>
                  <Box display="flex" alignItems="center" marginTop={2}>
                    <TextField
                      label="Purchase Credits"
                      value={purchaseAmount}
                      onChange={handlePurchaseAmountChange}
                      required
                      helperText="Minimum $10"
                      style={{ width: "180px", marginRight: "16px" }}
                      inputProps={{ maxLength: 6, inputMode: "decimal" }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePurchaseCredits}
                      disabled={purchaseLoading || parseFloat(purchaseAmount) < 10}
                    >
                      {purchaseLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Purchase Credits"
                      )}
                    </Button>
                  </Box>
                  <Box display="flex" justifyContent="space-between" marginTop={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePurchaseLabels}
                      disabled={!canPurchaseLabels()}
                    >
                      {purchaseLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        "Purchase Labels"
                      )}
                    </Button>
                    <Box display="flex" justifyContent="center" marginTop={2}>
  <Button
    variant="contained"
    color="secondary"
    onClick={handleDownloadUPSLabels}
    disabled = {upsLabelsLoading}
  >
    Download UPS Labels
  </Button>
</Box>
<Box display="flex" flexDirection="column" marginTop={3} gap={2}>
  <Typography variant="h6">Merge Uploaded Labels</Typography>

  <Typography variant="body1" gutterBottom>
    Upload your UPS Merged Labels PDF:
  </Typography>
  <input
    type="file"
    accept="application/pdf"
    onChange={(e) => setUpsFile(e.target.files[0] || null)}
  />
  <Typography variant="caption" color="textSecondary">
    This should be the merged UPS labels PDF you downloaded.
  </Typography>

  <Typography variant="body1" gutterBottom marginTop={2}>
    Upload your Stallion Labels PDF:
  </Typography>
  <input
    type="file"
    accept="application/pdf"
    onChange={(e) => setStallionFile(e.target.files[0] || null)}
  />
  <Typography variant="caption" color="textSecondary">
    This is the Stallion labels PDF you generated.
  </Typography>

  <Button
    variant="contained"
    color="secondary"
    onClick={handleMergeUploadedLabels}
    disabled={!upsFile || !stallionFile}
    style={{ marginTop: '16px' }}
  >
    Merge Labels
  </Button>
</Box>


                  </Box>
                </>
              ) : (
                <Typography variant="h6" color="error">
                  {error}
                </Typography>
              )}
            </>
          )}
        </Paper>
        {purchaseComplete && (
          <Box display="flex" justifyContent="center" marginTop={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setRefreshUpsLabels(!refreshUpsLabels)}
            >
              Refresh UPS Labels
            </Button>
          </Box>
        )}
      </Grid>
    </Grid>
    <Dialog 
      open={!!error} 
      onClose={() => setError('')}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ whiteSpace: 'pre-line' }}>
          {error}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setError('')} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  </Container>
);
};

export default PurchaseLabels;