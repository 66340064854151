import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Paper, TextField, Typography, Container, Alert, Chip } from '@mui/material';
import { USAContext } from './USAContext';

const API_URL = process.env.REACT_APP_API_URL;

const ValidateShipmentInfo = ({ setIsStepValidated }) => {
    const { shipmentInfo, selectedPlan, handleNext } = useContext(USAContext);
    const [pdfUrls, setPdfUrls] = useState([]); // Store URLs for all shipment labels
    const [shipmentAddresses, setShipmentAddresses] = useState({}); // Unique address fields for each shipment
    const [confirmedShipments, setConfirmedShipments] = useState([]); // Track confirmed shipments
    const [adjustedAddresses, setAdjustedAddresses] = useState({}); // Store adjusted addresses from the database
    const navigate = useNavigate();

    // Fetch shipment labels and adjusted addresses for all shipments in the confirmed placement
    useEffect(() => {
        if (selectedPlan) {
            fetchShipmentLabels();
            fetchAdjustedAddresses();
        }
    }, [selectedPlan]);


    
    useEffect(() => {
        if (pdfUrls.length > 0) {
            fetchAdjustedAddresses();
        }
    }, [pdfUrls]); // Add pdfUrls as a dependency
    
    const fetchShipmentLabels = async () => {
        if (!selectedPlan || !selectedPlan.placement_options) return;

        const confirmedPlacement = selectedPlan.placement_options.find(option => option.confirmed_at);
        if (!confirmedPlacement) {
            console.error("No confirmed placement option found.");
            return;
        }

        const shipmentLabels = [];
        for (const shipment of confirmedPlacement.shipments) {
            try {
                const response = await fetch(`https://app.yyzprep.ca/api/fba-transport/v2024/plans/${selectedPlan.id}/labels?shipment_id=${shipment.shipment_id}&api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq`);
                if (!response.ok) {
                    throw new Error(`Failed to fetch labels for shipment ID: ${shipment.shipment_id}`);
                }
                const data = await response.json();
        
                // Try to find the 4" x 6" package label first
                let label = data.data.find(label => label.name.includes('4" x 6"') && label.type === 'package');
        
                // If 4" x 6" label is not found, fall back to the 8.5" x 11" label
                if (!label) {
                    label = data.data.find(label => label.name.includes('8.5') && label.type === 'package');
                }
        
                // If a label is found, add it to the shipmentLabels array
                if (label) {
                    shipmentLabels.push({
                        shipmentId: shipment.shipment_id,
                        url: label.url,
                        address: shipment.destination.address,
                        warehouseId: shipment.destination.warehouse_id
                    });
                } else {
                    console.error("No suitable label found for shipment ID: " + shipment.shipment_id);
                }
            } catch (error) {
                console.error('Error fetching labels for shipment ID:', shipment.shipment_id, error);
            }
        }

        if (shipmentLabels.length > 0) {
            setPdfUrls(shipmentLabels);
        } else {
            console.error("No valid 4x6 package labels found for any shipment.");
        }
    };

    const fetchAdjustedAddresses = async () => {
        try {
            const response = await fetch(`${API_URL}/api/warehouse-addresses`);
            const data = await response.json();
            const adjustedAddressesMap = {};
    
            // Populate adjustedAddressesMap with data from the database
            data.forEach(address => {
                adjustedAddressesMap[address.warehouse_id] = address;
            });
    
            // Initialize shipmentAddresses with adjusted or original addresses
            const updatedAddresses = {};
            pdfUrls.forEach(shipment => {
                const adjustedAddress = adjustedAddressesMap[shipment.warehouseId];
                if (adjustedAddress) {
                    // Use adjusted address from the database
                    updatedAddresses[shipment.shipmentId] = {
                        address: adjustedAddress.address_line_1,
                        city: adjustedAddress.city,
                        state: adjustedAddress.state_or_province_code,
                        zip: adjustedAddress.postal_code,
                    };
                } else {
                    // Use original address from the API
                    updatedAddresses[shipment.shipmentId] = {
                        address: shipment.address.address_line_1 || '',
                        city: shipment.address.city || '',
                        state: shipment.address.state_or_province_code || '',
                        zip: shipment.address.postal_code || '',
                    };
                }
            });
    
            // Update shipmentAddresses state
            setShipmentAddresses(updatedAddresses);
            setAdjustedAddresses(adjustedAddressesMap);
        } catch (error) {
            console.error('Error fetching adjusted addresses:', error);
        }
    };
    // Handle address changes for a specific shipment
    const handleAddressChange = (shipmentId, field, value) => {
        setShipmentAddresses((prev) => ({
            ...prev,
            [shipmentId]: {
                ...prev[shipmentId],
                [field]: value,
            },
        }));
    };

    // Handle shipment confirmation
    const handleConfirmShipment = async (shipmentId, warehouseId, originalAddress) => {
        const address = shipmentAddresses[shipmentId];

        // Check if the address has been edited
        const isAddressEdited = (
            address.address !== originalAddress.address_line_1 ||
            address.city !== originalAddress.city ||
            address.state !== originalAddress.state_or_province_code ||
            address.zip !== originalAddress.postal_code
        );

        if (isAddressEdited) {
            try {
                await fetch(`${API_URL}/api/warehouse-addresses`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        warehouse_id: warehouseId,
                        address_line_1: address.address,
                        city: address.city,
                        state_or_province_code: address.state,
                        postal_code: address.zip,
                        is_adjusted: true,
                    }),
                });
            } catch (error) {
                console.error('Error confirming shipment:', error);
                return;
            }
        }

        setConfirmedShipments((prev) => [...prev, shipmentId]);
    };

    // Check if all shipments are confirmed
    const allShipmentsConfirmed = pdfUrls.length > 0 && pdfUrls.every(shipment => confirmedShipments.includes(shipment.shipmentId));

    // Handle validation and proceed to the next step
    const handleValidate = () => {
        if (allShipmentsConfirmed) {
            console.log('All shipments confirmed and validated.');
            setIsStepValidated(true);
            handleNext(); // Proceed to the next step
        } else {
            console.error('Not all shipments are confirmed.');
        }
    };

    // Update where you handle photos in the component
    const handlePhotoDisplay = (photos) => {
        if (!Array.isArray(photos)) return [];
        
        return photos.map(photo => {
            // Handle both old format (string) and new format (object)
            return typeof photo === 'string' ? { url: photo } : photo;
        });
    };

    return (
        <Container>
            <Grid container spacing={3}>
                {pdfUrls.length > 0 && (
                    <Grid item xs={12}>
                        <Typography variant="h5">Shipment Labels</Typography>
                        {pdfUrls.map((shipment, index) => {
                            const adjustedAddress = adjustedAddresses[shipment.warehouseId];
                            const isAdjusted = adjustedAddress && adjustedAddress.is_adjusted;

                            return (
                                <Paper key={index} style={{ padding: '16px', marginBottom: '8px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography variant="h6">Shipment ID: {shipment.shipmentId}</Typography>
                                            {shipment.url ? (
                                                <embed
                                                    src={shipment.url}
                                                    width="100%"
                                                    height="500px"
                                                    type="application/pdf"
                                                    style={{ border: 'none' }}
                                                    onLoad={() => window.scrollTo(0, 0)} // Prevent printing popup
                                                />
                                            ) : (
                                                <Alert severity="error">No 4x6 package label found for this shipment.</Alert>
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h6">Address Form</Typography>
                                            {isAdjusted && (
                                                <Chip
                                                    label="Adjusted Address (Pulled from Database)"
                                                    color="info"
                                                    variant="outlined"
                                                    style={{ marginBottom: '8px' }}
                                                />
                                            )}
                                            <TextField
                                                label="Address"
                                                fullWidth
                                                value={shipmentAddresses[shipment.shipmentId]?.address || ''}
                                                onChange={(e) => handleAddressChange(shipment.shipmentId, 'address', e.target.value)}
                                                required
                                                style={{ marginBottom: '8px' }}
                                            />
                                            <TextField
                                                label="City"
                                                fullWidth
                                                value={shipmentAddresses[shipment.shipmentId]?.city || ''}
                                                onChange={(e) => handleAddressChange(shipment.shipmentId, 'city', e.target.value)}
                                                required
                                                style={{ marginBottom: '8px' }}
                                            />
                                            <TextField
                                                label="State"
                                                fullWidth
                                                value={shipmentAddresses[shipment.shipmentId]?.state || ''}
                                                onChange={(e) => handleAddressChange(shipment.shipmentId, 'state', e.target.value)}
                                                required
                                                style={{ marginBottom: '8px' }}
                                            />
                                            <TextField
                                                label="Zip"
                                                fullWidth
                                                value={shipmentAddresses[shipment.shipmentId]?.zip || ''}
                                                onChange={(e) => handleAddressChange(shipment.shipmentId, 'zip', e.target.value)}
                                                required
                                                style={{ marginBottom: '8px' }}
                                            />
                                            <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={3}>
                                                {confirmedShipments.includes(shipment.shipmentId) ? (
                                                    <Typography variant="body1" color="success">
                                                        Confirmed ✅
                                                    </Typography>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleConfirmShipment(shipment.shipmentId, shipment.warehouseId, shipment.address)}
                                                        disabled={confirmedShipments.includes(shipment.shipmentId)}
                                                    >
                                                        Confirm Shipment
                                                    </Button>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            );
                        })}
                    </Grid>
                )}
            </Grid>
            <Box display="flex" justifyContent="space-between" marginTop={3}>
                {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate(`/usa/${selectedPlan.id}/fda`)}
                >
                    Back
                </Button> */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleValidate}
                    disabled={!allShipmentsConfirmed}
                >
                    Validate and Proceed
                </Button>
            </Box>
        </Container>
    );
};

export default ValidateShipmentInfo;