import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

export const BoxContext = createContext();

export const BoxProvider = ({ children }) => {
  const { shipmentId } = useParams(); // Use useParams to get the shipmentId from the URL
  const API_URL = process.env.REACT_APP_API_URL;

  const [boxes, setBoxes] = useState([]);
  const [shipmentInfo, setShipmentInfo] = useState(null);
  const [shipmentItems, setShipmentItems] = useState([]);
  const [asinData, setAsinData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const [clientInfo, setClientInfo] = useState(null);
  const [standardBoxes, setStandardBoxes] = useState([]);
  const [shelvingData, setShelvingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null); // New state variable
  const [selectedNickname, setSelectedNickname] = useState(null);
  const [packingGroups, setPackingGroups] = useState([]);
  const [selectedPackingGroup, setSelectedPackingGroup] = useState(null);

  const fetchShipmentData = useCallback(async () => {
    setLoading(true);
    try {
      const [shipmentResponse, itemsResponse] = await Promise.all([
        fetch(`https://app.yyzprep.ca/api/shipments/outbound/${shipmentId}`, {
          headers: {
            'Authorization': 'Bearer Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(response => response.json()),
        fetch(`https://app.yyzprep.ca/api/shipments/outbound/${shipmentId}/outbound-shipment-item`, {
          headers: {
            'Authorization': 'Bearer Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(response => response.json())
      ]);

      setShipmentInfo(shipmentResponse.shipment);
      setShipmentItems(itemsResponse.items);
      await getPlans();  // Add this line

      const asinList = itemsResponse.items.map(item => item.item.asin);

      const [asinDataResponse, servicesDataResponse, clientsDataResponse] = await Promise.all([
        fetch(`${API_URL}/api/get_asin_data`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ asins: asinList })
        }).then(response => response.json()),
        fetch('https://app.yyzprep.ca/api/services?type[]=outbound_shipment_item&api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq')
          .then(response => response.json()),
        fetch(`${API_URL}/api/get_clients`)
          .then(response => response.json())
      ]);

      setAsinData(asinDataResponse);
      setServicesData(servicesDataResponse);
      setClientsData(clientsDataResponse);

      const skuList = itemsResponse.items.map(item => item.item.merchant_sku);
      const shelvingDataResponse = await fetch(`${API_URL}/api/get_shelving_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ skus: skuList }),
      }).then(response => response.json());

      setShelvingData(shelvingDataResponse);

      const client = clientsDataResponse.find(client => client.id === shipmentResponse.shipment.team_id);
      if (client) {
        setClientInfo({ 
          name: client.name,
          id: client.id,
          country: client.name.includes('(US)') ? 'USA' : client.name.includes('(CA)') ? 'Canada' : 'Unknown',
          email: client.email
        });
      }

      const boxData = await fetchBoxes();
      setBoxes(boxData);

      await fetchStandardBoxes();

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }, [shipmentId]);



  const refreshShelving = async () => {
    const shelvingDataResponse = await fetch(`${API_URL}/api/get_shelving_data`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ skus: shipmentItems.map(item => item.item.merchant_sku) }),
    }).then(response => response.json());

    setShelvingData(shelvingDataResponse);
  };

  const getPlans = async () => {
    try {
      const response = await fetch(`https://app.yyzprep.ca/api/fba-transport/v2024/plans?api_token=Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq&outbound_shipment_id=${shipmentId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch plans');
      }
      const data = await response.json();
      setPlans(data.data);
      console.log('Fetched plans:', data.data);
    } catch (error) {
      console.error('Error fetching plans:', error);
    }
  };



  
  const reloadShipmentData = useCallback(async () => {
    try {
      const [shipmentResponse, itemsResponse] = await Promise.all([
        fetch(`https://app.yyzprep.ca/api/shipments/outbound/${shipmentId}`, {
          headers: {
            'Authorization': 'Bearer Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(response => response.json()),
        fetch(`https://app.yyzprep.ca/api/shipments/outbound/${shipmentId}/outbound-shipment-item`, {
          headers: {
            'Authorization': 'Bearer Yh7l5CUTaZ1nIgAueWglafvm616hchHFFZxRjKjPHNBjB19b2jTDgGoCSpeq',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(response => response.json()),
        await getPlans()
      ]);
  
      setShipmentInfo(shipmentResponse.shipment);
      setShipmentItems(itemsResponse.items);
      
      console.log("Set shipment items to")
      console.log(itemsResponse.items)
  
    } catch (error) {
      console.error('Error reloading shipment data:', error);
    }
  }, [shipmentId]);
  

  const fetchStandardBoxes = async () => {
    try {
      const response = await fetch(`${API_URL}/api/get_standard_boxes`);
      if (!response.ok) {
        throw new Error('Failed to fetch standard boxes');
      }
      const data = await response.json();
      setStandardBoxes(data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching standard boxes:', error);
    }
  };

  const reloadAsinData = async () => {
    try {
      // Assuming itemsResponse is accessible here, otherwise pass it as a parameter to this function
      const asinList = shipmentItems.map(item => item.item.asin);
  
      const response = await fetch(`${API_URL}/api/get_asin_data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ asins: asinList })
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch ASIN data');
      }
  
      const data = await response.json();
      setAsinData(data);
      console.log('ASIN data reloaded successfully:', data);
    } catch (error) {
      console.error('Error fetching ASIN data:', error);
    }
  };
  


  useEffect(() => {
    console.log("Selected plan changed, adjusting packing groups now..");
    
    if (selectedPlan?.packing_options) {
      // Filter packing options with confirmed_at
      const confirmedOptions = selectedPlan.packing_options.filter(po => po.confirmed_at);
      
      if (confirmedOptions.length > 0) {
        // Find the most recent packing option
        const latestOption = confirmedOptions.reduce((latest, current) => 
          new Date(current.confirmed_at) > new Date(latest.confirmed_at) ? current : latest
        );
        
        setPackingGroups(latestOption.packing_groups);
      } else {
        setPackingGroups([]);
      }
    } else {
      setPackingGroups([]);
    }
  }, [selectedPlan]);
  

  const fetchBoxes = useCallback(async () => {
    try {
      const endpoint = `${API_URL}/api/get_box_contents/${selectedPlan.id}`;
      const response = await fetch(endpoint);
      const data = await response.json();
      if (Array.isArray(data)) {
        const groupedData = groupAndSortByBoxNumber(data);
        console.log("Fetched boxes: ", groupedData);
        setBoxes(groupedData);
        return groupedData;
      } else {
        console.error('dbResponse is not an array:', data);
        return [];
      }

    } catch (error) {
      console.error('Error fetching boxes:', error);
      return [];
    }
  }, [selectedPlan]);

  useEffect(() => {
    fetchShipmentData();
  }, [fetchShipmentData]);


  const updateShelvingQty = async (key, shelfCode, newQty, SKU ) => {
    const id_to_use = selectedPlan ? selectedPlan.id : shipmentId; // Use selectedPlan's ID if available, otherwise use "a"

    try {
      const response = await fetch(`${API_URL}/api/update_shelving_qty`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key,
          qty: newQty,
          action_type: "BOX_CONTENTS_ADJUSTMENT",
          sku: SKU,
          shelfCode: shelfCode,
          shipmentId: id_to_use, // Use the conditionally set shipmentId
          employee: selectedNickname
        }),
      });


      if (response.ok) {
        refreshShelving();
        console.log('Shelving quantity updated successfully');
      } else {
        console.error('Failed to update shelving quantity');
        alert('Failed to update shelving quantity');
      }
    } catch (error) {
      console.error('Error updating shelving quantity:', error);
      alert('Error updating shelving quantity');
    }
  };

  const removeShelving = async (key,shelfCode, SKU ) => {
    const id_to_use = selectedPlan ? selectedPlan.id : shipmentId; // Use selectedPlan's ID if available, otherwise use "a"

    try {
      const response = await fetch(`${API_URL}/api/update_shelving_qty`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          key,
          sku: SKU,
          qty: 0,
          shelfCode: shelfCode,
          action_type: "BOX_CONTENTS_REMOVEFROMSHELF",
          shipmentId: id_to_use, // Use the conditionally set shipmentId
          employee: selectedNickname
        }),
      });

      if (response.ok) {
        refreshShelving();
        console.log('Shelving removed successfully');
      } else {
        console.error('Failed to remove shelving');
        alert('Failed to remove shelving');
      }
    } catch (error) {
      console.error('Error removing shelving:', error);
      alert('Error removing shelving');
    }
  };


  return (
    <BoxContext.Provider value={{ 
      boxes, 
      shipmentInfo, 
      shipmentItems, 
      asinData, 
      servicesData, 
      refreshStandardBoxes: fetchStandardBoxes,
      standardBoxes,
      reloadAsinData,
      setBoxes,
      clientsData, 
      clientInfo, 
      shelvingData,
      plans,
      reloadShipmentData,
      updateShelvingQty,
      removeShelving,
      selectedPlan,
      selectedNickname,
      setSelectedNickname,
      setSelectedPlan,
      loading,
      refreshBoxes: fetchBoxes,
      setSelectedPackingGroup,
      packingGroups,
      selectedPackingGroup
    }}>
      {children}
    </BoxContext.Provider>
  );
};

const groupAndSortByBoxNumber = (data) => {
  const groupedData = data.reduce((acc, item) => {
    const boxNumber = item.BOX_NUMBER;
    if (!acc[boxNumber]) {
      acc[boxNumber] = {
        length: item.LENGTH,
        width: item.WIDTH,
        height: item.HEIGHT,
        weight: item.WEIGHT,
        items: []
      };
    }
    
    // Only push non-empty items into the items array
    if (item.FNSKU || item.SKU || item.ASIN || item.QTY || item.TITLE) {
      acc[boxNumber].items.push(item);
    }

    return acc;
  }, {});

  const sortedData = Object.keys(groupedData)
    .sort((a, b) => Number(a) - Number(b))
    .map(boxNumber => ({
      boxNumber,
      length: groupedData[boxNumber].length,
      width: groupedData[boxNumber].width,
      height: groupedData[boxNumber].height,
      weight: groupedData[boxNumber].weight,
      items: groupedData[boxNumber].items
    }));

  return sortedData;
};
